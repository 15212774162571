<template>
  <HeaderBar @change-view="changeView" />
  <LoadingScreen />
  <NotificationPopup />
  <popup-text />
  <MapView v-if="selectedView === 'map'" />
  <HelpView v-if="selectedView === 'help'" />
  <TrainingToolView v-if="selectedView === 'trainingtool'" />
</template>

<script>
import MapView from './components/MapView.vue'
import HeaderBar from './components/HeaderBar.vue'
import TrainingToolView from "@/components/TrainingToolView.vue";

import 'bootstrap/dist/css/bootstrap.css';
import '@fortawesome/fontawesome-free/css/all.css'
import 'bootstrap';
import HelpView from "@/components/HelpView.vue";
import NotificationPopup from "@/components/Notification/NotificationPopup.vue"; // Import Bootstrap JavaScript
import PopupText from "@/components/Notification/PopupText.vue";
import LoadingScreen from "@/components/Notification/LoadingScreen.vue";

export default {
  name: 'App',
  components: {
    LoadingScreen,
    NotificationPopup,
    HelpView,
    TrainingToolView,
    MapView,
    HeaderBar,
    PopupText
  },
  methods: {
    changeView(view) {
      this.selectedView = view
      this.$globalEvents.emit('change-view', view)
    }
  },
  data() {
    return {
      selectedView: 'map'
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 0px;
}

.Toastify__toast-container {
  z-index: 20000; /* Set this to any value that suits your needs */
}
</style>

<style src="./assets/css/main.css"></style>