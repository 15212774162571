<template>
  <div v-if="$droneSystem.selectedDrone">
    <!-- Description -->
    <div class="row">
      <div class="col-md-12">
        <h4>Fire Seeding</h4>
        <p>This script is meant for sabotage class specifically fire seeder drones.</p>
        <p>Click on the map to select a point for fire seeding.</p>
        <p>At each point, the drone will wait until recharged and then seed the fire. After which, it flies aways.</p>
      </div>
    </div>


    <div class="row">
      <div class="col-md-12">
        <span style="color: purple;">Click on map to select a point</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <!-- Reset button -->
        <button class="btn btn-danger" @click="reset">Reset</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    this.$globalEvents.on('mapClicked', this.clickMap)

    // Delete markers created by this script
    this.$globalEvents.on('closeScriptView', this.reset)

    // Update markers on event openScriptView
    this.$globalEvents.on('openScriptView', this.updateMarkers)
  },
  data() {
    return {
      repeatEnabled: false
    }
  },
  methods: {
    reset() {
      const selectedDrone = this.$droneSystem.selectedDrone
      if (selectedDrone && selectedDrone.payload['currentScript'] === 'path') {
        this.$droneSystem.selectedDrone.payload['pathMarkers'] = []
        this.$droneSystem.updateState(this.$droneSystem.selectedDrone)
        this.$mapSystem.deleteMarkerByCreatedBy('pathScript')
        this.$globalEvents.emit('refreshMap')
      }
    },
    updateMarkers() {
      const selectedDrone = this.$droneSystem.selectedDrone
      if (selectedDrone && selectedDrone.payload['currentScript'] === 'path') {
        // Set pathMarker details
        if (!this.$droneSystem.selectedDrone.payload['pathMarkers']) {
          this.$droneSystem.selectedDrone.payload['pathMarkers'] = []
        }
        this.$droneSystem.selectedDrone.payload['pathMarkers'].forEach((marker) => {
          this.$mapSystem.addMarker({
            id: marker.id,
            createdBy: 'pathScript',
            position: {
              lat: marker.lat,
              lng: marker.lng
            },
            icon: {
              url: 'https://maps.google.com/mapfiles/ms/icons/yellow.png',
              scaledSize: {
                width: 24,
                height: 24
              }
            }
          })
        })
        this.$globalEvents.emit('refreshMap')
      }
    },
    clickMap(event) {
      const selectedDrone = this.$droneSystem.selectedDrone
      if (selectedDrone && selectedDrone.payload['currentScript'] === 'path') {
        if (!this.$droneSystem.selectedDrone.payload['pathMarkers']) {
          this.$droneSystem.selectedDrone.payload['pathMarkers'] = []
        }
        // Random id
        const generatedId = Math.random().toString(36).substring(7)
        this.$droneSystem.selectedDrone.payload['pathMarkers'].push({
          id: generatedId,
          lat: event.latLng.lat(),
          lng: event.latLng.lng()
        })
        this.$droneSystem.updateState(this.$droneSystem.selectedDrone)
        this.updateMarkers()
      }
    }
  }
}
</script>