<template>
  <div class="object-view-content-actions" v-if="selectedObject.MY_CLASS === 'DetectedObject'">
    <div class="mb-2">
      <button @click="setAs('target')" class="color-red mr-1">Target</button>
      <button @click="setAs('friendly')" class="color-green mr-1">Friendly</button>
      <button @click="setAs('unknown')" class="color-orange">Unknown</button>
    </div>
    <div>
      <button @click="deleteObject" class="color-red">Delete</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selectedObject: {
      type: Object,
      required: true
    }
  },
  methods: {
    setAs(type) {
      const object = this.selectedObject
      object.type = type
      this.$objectSystem.updateState(object)
      // Refresh map view
      this.$globalEvents.emit('refreshMap')
    },
    deleteObject() {
      this.$objectSystem.deleteObject(this.selectedObject)
      // Refresh map view
      this.$globalEvents.emit('refreshMap')
    }
  }
}
</script>