<template>
  <!-- Skip images -->
  <div v-if="outputData.length < 1000">
    <span v-for="(item, index) in outputData" :key="index" :class="getClass(index)">
      <span v-if="outputDataLabels[index]" @click="clickLabel(index, item, outputDataLabels[index])">
        {{ outputDataLabels[index] }}: {{ item }}
      </span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    outputData: {
      type: Array,
      required: true
    },
    outputDataLabels: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      activeLabel: null
    }
  },
  methods: {
    getClass(index) {
      return {
        'badge': true,
        'output': true,
        'bg-primary': index === this.activeLabel,
        'bg-secondary': index !== this.activeLabel
      }
    },
    clickLabel(index, value, label) {
      // Set active label
      this.activeLabel = index

      // Emit event
      this.$emit('click-label', {
        index: index,
        value: value,
        label: label
      })
    }
  }
}
</script>

<style>
.badge.output {
  cursor: pointer;
}
</style>