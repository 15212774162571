<template>
  <!-- Edit Modal -->
  <div class="modal modal-xl" tabindex="-1" role="dialog" v-if="showModal && collectedData">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Classify data for training</h5>
          <button type="button" class="close" @click="$emit('close-modal')">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row mb-2">
            <div class="col-6">
              <!-- Status -->
              <div class="row">
                <div class="form-group">
                  <label for="status">Status</label>
                  <select class="form-control" id="status" v-model="trainingData.status">
                    <option value="pending">Pending</option>
                    <option value="completed">Completed</option>
                  </select>
                </div>
              </div>
              <!-- Selected label value edit field -->
              <div class="row mt-2" v-if="selectedLabel">
                <div class="col">
                  <!-- Selected label value edit field -->
                  <div class="form-group">
                    <label for="selectedLabel">Selected value of neuron #{{ selectedLabel.index }} ({{ selectedLabel.label }})</label>
                    <input type="text" class="form-control" id="selectedLabel" v-model="selectedLabel.value" />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6">
              <!-- Output badges -->
              <div class="row" v-if="trainingData.output_data">
                <div class="col">
                  <h6>Output data</h6>
                  <output-data :output-data-labels="trainingData.output_data_labels" :output-data="trainingData.output_data" @click-label="clickLabel" />
                </div>
              </div>
            </div>
          </div>
          <div class="row" v-if="trainingData.input_data">
            <div class="col">
              <h6>Input data</h6>
              <input-data :input-data-labels="trainingData.input_data_labels" :input-data="trainingData.input_data" />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <!-- predict -->
          <button type="button" class="btn btn-secondary" :disabled="predictDisabled" @click="predict(collectedData.id)">Predict</button>
          <button type="button" class="btn btn-primary" @click="saveSettings">Save</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Background overlay -->
  <div class="modal-backdrop" v-if="showModal"></div>
</template>
<script>
import InputData from "@/components/TrainingTool/InputData.vue";
import OutputData from "@/components/TrainingTool/OutputData.vue";

export default {
  components: {InputData, OutputData},
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    collectedData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      selectedLabel: null,
      trainingData: {},
      predictDisabled: false
    }
  },
  watch: {
    showModal: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.selectedLabel = null;
        }
      },
      immediate: true
    },
    collectedData: {
      handler() {
        if (this.collectedData) {
          this.$globalEvents.emit('loading-screen:show');
          this.$trainingSystem.getTrainingDataById(this.collectedData.id)
            .then(response => {
              this.trainingData = response.data.data
              this.$globalEvents.emit('loading-screen:hide');
            })
            .catch(error => {
              console.log(error)
              this.$globalEvents.emit("show-notification", "Error fetching training data", "error")
              this.$globalEvents.emit('loading-screen:hide');
            })
        } else {
          this.trainingData = {}
        }
      },
      immediate: true
    }
  },
  methods: {
    predict(id) {
      this.predictDisabled = true
      this.$http.get(`/api/training-data/predict/${id}`)
        .then((response) => {
          this.predictDisabled = false
          this.$globalEvents.emit("show-notification", "Prediction completed", "success")
          // Update training data output value
          this.trainingData.output_data = response.data.data
        })
        .catch(error => {
          this.predictDisabled = false
          console.log(error)
          this.$globalEvents.emit("show-notification", "Error predicting data", "error")
        })
    },
    clickLabel(label) {
      // Update existing values
      if (this.selectedLabel) {
        this.trainingData.output_data.forEach((item, index) => {
          if (index === this.selectedLabel.index) {
            this.trainingData.output_data[index] = this.selectedLabel.value
          }
        })
      }
      this.selectedLabel = label
    },
    saveSettings() {
      // Update existing values
      if (this.selectedLabel) {
        this.trainingData.output_data.forEach((item, index) => {
          if (index === this.selectedLabel.index) {
            this.trainingData.output_data[index] = this.selectedLabel.value
          }
        })
      }

      const id = this.trainingData.id

      this.$globalEvents.emit('loading-screen:show');
      this.$http.put(`/api/training-data/${id}`, this.trainingData)
        .then(() => {
          this.$globalEvents.emit('loading-screen:hide');
          this.$globalEvents.emit('training-data-updated')
          this.$emit('close-modal')
          this.$globalEvents.emit("show-notification", "Training data updated", "success")
        })
        .catch(error => {
          console.log(error)
          this.$globalEvents.emit('loading-screen:hide');
          this.$globalEvents.emit("show-notification", "Error updating training data", "error")
        })
    }
  }
}
</script>