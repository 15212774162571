<template>
  <div class="row">
    <!-- Select: Current action ID -->
    <div class="col-3">
      <label for="commandId">
        <info-message message="Unique identifier of an action currently being performed." />
        Current Action
      </label>
      <select id="commandId" class="form-control" v-model="commandId">
        <option v-for="action in actions" :key="action.id" :value="action.id">{{ action.label }}</option>
      </select>
    </div>
  </div>
</template>

<script>
import InfoMessage from "@/components/Notification/InfoMessage.vue"

export default {
  components: {
    InfoMessage
  },
  data() {
    return {
      commandId: 1,
      actions: [
          // @TODO: Belongs in backend.
        { id: 1, label: 'Takeoff' },
        { id: 2, label: 'Land' },
        { id: 3, label: 'Stay still' }
      ]
    }
  }
}
</script>