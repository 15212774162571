<template>
  <div class="row">
    <!-- Drone coord X -->
    <div class="col-4" v-if="drone.camera.canMoveX">
      <label for="rotation-x">
        <info-message message="Modify the rotation of the camera, hotkey: Left/Right Arrow" />
        Camera Rotation X ({{ rotationX }})
      </label>
      <input id="rotation-y" type="range" min="0" max="180" class="m-1"  :disabled="!drone.isFlying() || !drone.manualControlEnabled()" @change="updateTargets()" v-model="rotationX">
    </div>
    <!-- Drone coord Y -->
    <div class="col-4" v-if="drone.camera.canMoveY">
      <label for="rotation-y">
        <info-message message="Modify the rotation of the camera, hotkey: Up/Down Arrow" />
        Camera Rotation Y ({{ rotationY }})
      </label>
      <input id="rotation-y" type="range" min="0" max="180" class="m-1"  :disabled="!drone.isFlying() || !drone.manualControlEnabled()" @change="updateTargets()" v-model="rotationY">
    </div>
    <!-- Zoom level -->
    <div class="col-4" v-if="drone.camera.canZoom">
      <label for="zoom">
        <info-message message="Modify the zoom of the camera, hotkey: NumPad + / -" />
        Zoom Level ({{ zoom }})
      </label>
      <input id="zoom" type="range" min="0" max="100" class="m-1"  :disabled="!drone.isFlying() || !drone.manualControlEnabled()" @change="updateTargets()" v-model="zoom">
    </div>
  </div>
</template>

<script>
import InfoMessage from "@/components/Notification/InfoMessage.vue";

export default {
  components: {InfoMessage},
  data() {
    return {
      rotationX: 0,
      rotationY: 0,
      zoom: 0,
    };
  },
  props: {
    drone: Object,
  },
  created() {
    this.$globalEvents.onKeyPress('ArrowDown', this.handleKeyDown)
    this.$globalEvents.onKeyPress('ArrowUp', this.handleKeyUp)
    this.$globalEvents.onKeyPress('ArrowLeft', this.handleKeyLeft)
    this.$globalEvents.onKeyPress('ArrowRight', this.handleKeyRight)
    this.$globalEvents.onKeyPress('+', this.handleKeyAdd)
    this.$globalEvents.onKeyPress('-', this.handleKeySubtract)
    this.$globalEvents.onKeyRelease([
      'ArrowDown',
      'ArrowUp',
      'ArrowLeft',
      'ArrowRight',
      '+',
      '-'
    ], this.updateTargets)
  },
  beforeUnmount() {
    this.$globalEvents.removeKeyEvents([
      'ArrowDown',
      'ArrowUp',
      'ArrowLeft',
      'ArrowRight',
      '+',
      '-'
    ]);
  },
  methods: {
    updateTargets() {
      if (!this.$globalEvents.isComponentDisplayed(this) || !this.drone.manualControlEnabled()) return;
      this.drone.camera.setTargetRotation(this.rotationX, this.rotationY)
      this.drone.camera.setTargetZoom(this.zoom)
    },
    handleKeyDown() {
      if (!this.$globalEvents.isComponentDisplayed(this) || !this.drone.manualControlEnabled()) return;
      this.rotationY--;
      if (this.rotationY < 0) {
        this.rotationY = 0;
      }
    },
    handleKeyUp() {
      if (!this.$globalEvents.isComponentDisplayed(this) || !this.drone.manualControlEnabled()) return;
      this.rotationY++;
      if (this.rotationY > 180) {
        this.rotationY = 180;
      }
    },
    handleKeyLeft() {
      if (!this.$globalEvents.isComponentDisplayed(this) || !this.drone.manualControlEnabled()) return;
      this.rotationX--;
      if (this.rotationX < 0) {
        this.rotationX = 0;
      }
    },
    handleKeyRight() {
      if (!this.$globalEvents.isComponentDisplayed(this) || !this.drone.manualControlEnabled()) return;
      this.rotationX++;
      if (this.rotationX > 180) {
        this.rotationX = 180;
      }
    },
    handleKeyAdd() {
      if (!this.$globalEvents.isComponentDisplayed(this) || !this.drone.manualControlEnabled()) return;
      this.zoom = Math.min(this.zoom + 1, 100); // Increase zoom, but not more than 100
      if (this.zoom > 100) {
        this.zoom = 100;
      }
    },
    handleKeySubtract() {
      if (!this.$globalEvents.isComponentDisplayed(this) || !this.drone.manualControlEnabled()) return;
      this.zoom = Math.max(this.zoom - 1, 0); // Decrease zoom, but not less than 0
      if (this.zoom < 0) {
        this.zoom = 0;
      }
    }
  }
}
</script>