<template>
  <div v-if="marker.MY_CLASS === 'DetectedObject'">
    <div>Detected object ID: <div class="col badge bg-primary m-1">{{ marker.id }}</div></div>
    <div>Detected object type: <div class="col badge bg-primary m-1">{{ marker.type }}</div></div>
    <div>Last seen: <div class="col badge bg-primary m-1">{{ marker.lastSeen() }} Seconds ago</div></div>
  </div>
</template>

<script>


export default {
  name: 'DetectedObjectMarkerData',
  props: {
    marker: {
      type: Object,
      required: true
    }

  },
}
</script>