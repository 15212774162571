export default class UserSystem {
    constructor(appHandle) {
        this.appHandle = appHandle;
        this.user = null;
    }

    init() {
        // Check if we have a token in the local storage
        if (localStorage.getItem('token')) {
            // If we do, set it as the default header
            this.appHandle.getHttp().defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
        }
    }

    verifyToken() {
        return this.appHandle.getHttp().post('/api/verify-token');
    }

    login(email, password, remember) {
        return this.appHandle.getHttp().post('/api/login', {
            email: email,
            password: password,
            remember: remember // Pass the remember value to the server
        });
    }

    register(email, password) {
        return this.appHandle.getHttp().post('/api/register', {email, password});
    }

    logout() {
        return this.appHandle.getHttp().post('/api/logout');
    }

    getUser() {
        return this.appHandle.getHttp().get('/api/user');
    }

    getToken() {
        return sessionStorage.getItem('token');
    }

    setToken(token) {
        localStorage.setItem('token', token);
    }

    setUser(user) {
        this.user = user;
    }
    recoverPassword(email) {
        return this.appHandle.getHttp().post('/api/forgot-password', { email });
    }
}