import {createApp} from 'vue';
import App from './App.vue';
import LoginView from './LoginView.vue';
import * as VueGoogleMaps from 'vue3-google-map';
import { GoogleMapsConfig } from './features/googleMapsConfig'; // Adjust the path as needed
import Events from './features/events.js';
import axios from 'axios';
import { useToast } from 'vue-toastification';

// Drone system
import DroneSystem from './features/droneSystem.js';
import ObjectSystem from "@/features/objectSystem";
import MapSystem from "@/features/mapSystem";
import UserSystem from "@/features/userSystem";
import TrainingSystem from "@/features/trainingSystem";
import Socket from "@/features/socket";

const app = createApp(App);

// Create a new drone system
const droneSystem = new DroneSystem();
const objectSystem = new ObjectSystem();
const mapSystem = new MapSystem(app);
const userSystem = new UserSystem(app);
const trainingSystem = new TrainingSystem();
const eventsApp = new Events(app);
const socket = new Socket(app);

// Add extra systems to app
app.config.globalProperties.$droneSystem = droneSystem;
app.config.globalProperties.$objectSystem = objectSystem;
app.config.globalProperties.$userSystem = userSystem;
app.config.globalProperties.$mapSystem = mapSystem;
app.config.globalProperties.$trainingSystem = trainingSystem;
app.config.globalProperties.$globalEvents = eventsApp
app.config.globalProperties.$socket = socket;
app.config.globalProperties.$http = axios;
app.config.globalProperties.$toast = useToast();

app.config.globalProperties.$http.defaults.baseURL = process.env.VUE_APP_API_URL;

// Add getters to app
app.getDroneSystem = () => {
    return droneSystem;
}
app.getObjectSystem = () => {
    return objectSystem;
}
app.getUserSystem = () => {
    return userSystem;
}
app.getMapSystem = () => {
    return mapSystem;
}
app.getTrainingSystem = () => {
    return trainingSystem;
}
app.getGlobalEvents = () => {
    return eventsApp;
}
app.getSocket = () => {
    return socket;
}
app.getHttp = () => {
    return axios;
}

// Init systems
app.getUserSystem().init()
app.getUserSystem().verifyToken()
    .then(response => {
        userSystem.setUser(response.data.user);
        droneSystem.init(app);
        objectSystem.init(app);
        trainingSystem.init(app);

        // Add the Google Maps plugin to the app
        app.use(VueGoogleMaps, GoogleMapsConfig);

        app.mount('#app');
    })
    .catch(() => {
        const loginApp = createApp(LoginView);
        userSystem.setToken(null);
        loginApp.config.globalProperties.$userSystem = userSystem;
        loginApp.config.globalProperties.$http = axios;
        loginApp.config.globalProperties.$http.defaults.baseURL = process.env.VUE_APP_API_URL;
        loginApp.mount('#app');
    });