<template>
  <div class="secondary-icons" v-if="object.id && !object.isObject()">
    <!-- Low battery icon -->
    <div class="fade-in float-left" v-if="object.isDrone() && object.getBatteryLevel() < 10">
      <img src="@/assets/icons/drone/battery.png" title="Battery low" alt="low battery" />
    </div>
    <!-- Timeout icon -->
    <div class="fade-in float-left" v-if="object.isTimeout()">
      <img src="@/assets/icons/timeout.png" title="Lost connection" alt="timeout" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdditionalIcons',
  props: {
    object: Object,
  },
}
</script>