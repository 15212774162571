<template>
  <div class="diagnostics">
    <!-- Drone logs -->
    <div>
      <h3>Logs</h3>
    </div>

    <!-- Disabled textarea, meant for a log output -->
    <textarea class="logs log-entries" v-model="logs" disabled></textarea>
  </div>
</template>

<script>
export default {
  name: 'DiagnosticsView',
  props: {
    drone: Object,
  },
  data() {
    return {
      logs: '',
    };
  },
  created() {
    setInterval(this.updateLogs, 1000);
  },
  methods: {
    updateLogs() {
      if (!this.$globalEvents.isComponentDisplayed(this) || !this.drone.manualControlEnabled()) return;
      this.$droneSystem.getLogs(this.drone.id, (logs) => {
        this.logs = logs;
      })
    }
  }
};
</script>

<style>
.diagnostics {
  height: 100%; /* Adjust this value as needed */
  text-align: left;
}
.diagnostics .logs {
  width: 100%;
  height: 300px;
  resize: none;
}
</style>