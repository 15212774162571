<template>
  <div class="drone-info">
    <!-- Drone ID -->
    <div class="row text-left">
      <div class="col">
        <info-message message="The unique ID of the drone." />
        Drone ID
      </div>
      <div class="col"><span class="badge bg-primary">{{ drone.id }}</span></div>
    </div>
    <!-- Battery level -->
    <div class="row text-left">
      <div class="col">
        <info-message message="The battery level of the drone in percentage." />
        Battery
      </div>
      <div class="col"><span class="badge bg-primary">{{ drone.getBatteryLevel() }}%</span></div>
    </div>
    <!-- Coordinates -->
    <div class="row text-left">
      <div class="col">
        <info-message message="The latest coordinates of the drone." />
        Latest Coordinates
      </div>
      <div class="col"><span class="badge bg-primary">{{ drone.lat }}</span><span class="badge bg-primary">{{ drone.lng }}</span></div>
    </div>
    <!-- Altitude -->
    <div class="row text-left">
      <div class="col">
        <info-message message="The altitude of the drone in meters." />
        Altitude
      </div>
      <div class="col"><span class="badge bg-primary">{{ drone.getAltitude() }} meters</span></div>
    </div>
    <!-- Estimated range -->
    <div class="row text-left">
      <div class="col">
        <info-message message="The estimated range of the drone in meters." />
        Estimated range
      </div>
      <div class="col"><span class="badge bg-primary">{{ drone.getEstimatedRange() }} meters</span></div>
    </div>
    <!-- Speed -->
    <div class="row text-left">
      <div class="col">
        <info-message message="The speed of the drone in km/h." />
        Speed
      </div>
      <div class="col"><span class="badge bg-primary">{{ drone.getSpeed() }} km/h</span></div>
    </div>
    <!-- Current task -->
    <div class="row text-left">
      <div class="col">
        <info-message message="The current action the drone is performing." />
        Current task
      </div>
      <div class="col"><span class="badge bg-primary">{{ drone.getCurrentTask() }}</span></div>
    </div>
    <!-- Last seen -->
    <div class="row text-left">
      <div class="col">
        <info-message message="The last time any info was received from the drone." />
        Last seen
      </div>
      <div class="col">
        <span class="badge bg-primary" v-if="drone.lastSeen()">{{ drone.lastSeen() }} seconds ago</span>
        <span class="badge bg-primary" v-else>Not seen recently</span>
      </div>
    </div>
  </div>
</template>
<script>
import InfoMessage from "@/components/Notification/InfoMessage.vue";

export default {
  components: {
    InfoMessage
  },
  props: {
    drone: Object,
  },
};
</script>