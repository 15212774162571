<template>
  <div class="map">
    <GoogleMap
        ref="googleMapRef"
        :api-key="getApiKey()"
        style="width: 100%; height: 100%"
        :center="{ lat: 59.45279543191348, lng: 24.71555244417669 }"
        @click="clickMap"
        :zoom="15"
        :map-type-id="'satellite'"
        :options="$mapSystem.getMapOptions()"
    >
      <!-- Object markers -->
      <CustomMarker
          v-for="(marker, idx) in generateObjectMarkers"
          v-bind:key="idx"
          :options="marker"
          @click="markerClicked(marker)"
          @mouseover="onHoverMarker($event, marker)"
          @mouseout="onLeaveMarker"
      >
        <CustomMarkerContent :marker="marker" />
      </CustomMarker>
      <!-- Custom markers -->
      <Marker
          v-for="(marker, idx) in $mapSystem.getMarkers()"
          v-bind:key="idx"
          :options="marker"
          @mouseover="onHoverMarker($event, marker)"
          @mouseout="onLeaveMarker"
      />
      <!-- Drone circles -->
      <Circle
          v-for="(circle, idx) in generateDroneCircles"
          :key="idx"
          :options="circle.options"
      />
      <!-- Poly lines -->
      <Polyline
          v-for="(polyline, idx) in generatePolyLines"
          :key="idx"
          :options="polyline"
      />
      <!-- Custom markers -->
      <CustomMarker
          v-for="(marker, idx) in generateDroneMarkers"
          v-bind:key="idx"
          :options="marker"
          @click="markerClicked(marker)"
          @mouseover="onHoverMarker($event, marker)"
          @mouseout="onLeaveMarker"
      >
        <CustomMarkerContent :marker="marker" />
      </CustomMarker>
    </GoogleMap>
    <object-view v-if="selectedObject !== null" :selected-object="selectedObject" @close-object-view="closeSelection" />
    <marker-hover-modal :marker="hoveringOver" :coordinates="hoveringCoordinates" />
    <div>
      <drones-connected-label />
      <!-- Rest of the template -->
    </div>
  </div>
</template>

<script>
import ObjectView from "@/components/ObjectView.vue";
import { GoogleMap, Marker, Circle, Polyline, CustomMarker } from "vue3-google-map";
import {GoogleMapsConfig} from "@/features/googleMapsConfig";
import MarkerHoverModal from "@/modals/MarkerHoverModel.vue";
import CustomMarkerContent from "@/components/Map/CustomMarkerContent.vue";
import DronesConnectedLabel from "@/components/Drone/DronesConnectedLabel.vue";

export default {
  components: {CustomMarkerContent, DronesConnectedLabel, CustomMarker, MarkerHoverModal, ObjectView, GoogleMap, Marker, Circle, Polyline },
  name: 'MapView',
  data() {
    return {
      selectedObject: null,
      selectOverrideCallback: null,
      drones: [],
      objects: [],
      hoveringOver: null,
      hoveringCoordinates: null
    }
  },
  created() {
    this.$mapSystem.init(this.$refs.googleMapRef)
    this.$globalEvents.on('refreshMap', this.refreshMap)
    this.$globalEvents.on('objectSelectOverride', (callback) => {
      this.selectOverrideCallback = callback
    })
    this.$globalEvents.on('objects-updated', () => {
      this.objects = this.$objectSystem.getObjects()
      this.refreshMap()
    })
    this.$globalEvents.on('drones-updated', () => {
      this.drones = this.$droneSystem.getDrones()
      this.refreshMap()
    })
    this.$globalEvents.on('marker-clicked', marker => {
      this.markerClicked(marker)
    })

    this.objects = this.$objectSystem.getObjects()
    this.drones = this.$droneSystem.getDrones()
    this.refreshMap()
  },
  computed: {
    generateObjectMarkers() {
      return this.objects.map(object => {
        return {
          id: object.id,
          type: 'object',
          position: {
            lat: object.payload.lat,
            lng: object.payload.lng
          },
          model: object,
          icon: {
            url: object.getIcon(),
            scaledSize: {
              width: 32,
              height: 32
            }
          }
        }
      })
    },
    generatePolyLines() {
      const result = []
      const lines = this.$mapSystem.getLines()
      lines.forEach(line => {
        result.push({
          path: [
            { lat: line.from.lat, lng: line.from.lng },
            { lat: line.to.lat, lng: line.to.lng }
          ],
          strokeColor: line.color,
          strokeOpacity: 1.0,
          strokeWeight: 2
        })
      })

      return result
    },
    generateDroneMarkers() {
      return this.drones.map(drone => {
        return {
          id: drone.id,
          type: 'drone',
          position: {
            lat: drone.payload.lat,
            lng: drone.payload.lng
          },
          model: drone,
          icon: {
            url: drone.getIcon(),
            scaledSize: {
              width: 32,
              height: 32
            }
          }
        }
      })
    },
    generateDroneCircles() {
      return this.drones.map(drone => {
        return {
          options: {
            center: {
              lat: drone.payload.lat,
              lng: drone.payload.lng
            },
            radius: drone.getVisionRange(),
            strokeColor: "#0000AD",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: "#000000",
            fillOpacity: 0.2,
          }
        };
      });
    }
  },
  methods: {
    onHoverMarker(event, marker) {
      if (marker) {
        if (event.domEvent) {
          event = event.domEvent
        }
        this.hoveringOver = this.$mapSystem.findProperObjectFromMarker(marker)
        this.hoveringCoordinates = {
          x: event.pageX,
          y: event.pageY
        }
      } else {
        this.hoveringOver = null
        this.hoveringCoordinates = null
      }
    },
    onLeaveMarker() {
      this.hoveringOver = null
      this.hoveringCoordinates = null
    },
    getApiKey() {
      return GoogleMapsConfig.apiKey
    },
    refreshMap() {
      if (this.$refs.googleMapRef) {
        this.objects = this.$objectSystem.getObjects();
        this.drones = this.$droneSystem.getDrones();

        // Reload selected object
        if (this.selectedObject) {
          this.selectedObject = this.$mapSystem.reloadObject(this.selectedObject)
        }

        this.$refs.googleMapRef.$forceUpdate()
      }
    },
    clickMap(event) {
      // Global event
      this.$globalEvents.emit('mapClicked', event)
    },
    closeSelection() {
      this.selectedObject = null
      this.$droneSystem.selectedDrone = null
      this.$objectSystem.selectedObject = null
      this.$globalEvents.emit('closeScriptView')
    },
      // Focus on marker when selected
    focusOnPosition(position) {
      if (this.$refs.googleMapRef) {
        const googleMapInstance = this.$refs.googleMapRef.map;
        googleMapInstance.panTo(position);
      }
    },
    markerClicked(marker) {
      const selectedObject = this.$mapSystem.findProperObjectFromMarker(marker);
      this.selectObject(selectedObject);
      this.focusOnPosition(selectedObject.getPosition());
    },

    selectObject(object) {
      // if empty object, close view
      if (!object) {
        this.closeSelection()
        return
      }

      // If selected object is the same as the one already selected, close view
      if (this.selectedObject && this.selectedObject.id === object.id) {
        if (this.selectedObject.type === object.type) {
          this.closeSelection()
          return
        }
      }
      if (this.selectOverrideCallback) {
        this.selectOverrideCallback(object)
        return
      }
      // Emit close view
      this.$globalEvents.emit('closeScriptView')
      // Set selected object
      this.selectedObject = object
      this.$globalEvents.emit('objectSelected', object)
      if (object.isDrone()) {
        this.$droneSystem.selectedDrone = object;
        this.$objectSystem.selectedObject = null;
      } else {
        this.$objectSystem.selectedObject = object;
        this.$droneSystem.selectedDrone = null;
      }
      // Emit open view
      this.$globalEvents.emit('openScriptView')
      this.refreshMap()
    }
  }
}
</script>

<style src="../assets/css/map.css"></style>