<template>
  <div :class="'custom-marker ' + calculateClasses()">
    <div class="primary-icon">
      <img
          :src="marker.icon.url"
          alt="icon"
          :style="'width: ' + marker.icon.scaledSize.width + 'px; height: ' + marker.icon.scaledSize.height + 'px;'"
      />
    </div>
    <additional-icons :object="marker.model" />
  </div>
</template>

<script>

import AdditionalIcons from "@/components/Map/AdditionalIcons.vue";

export default {
  name: 'CustomMarkerContent',
  components: {AdditionalIcons},
  props: {
    marker: Object,
  },
  methods: {
    calculateClasses() {
      const classes = [];
      if (this.marker.model.isTimeout()) {
        classes.push('danger');
        classes.push('flashing');
      }
      // Drone specific
      if (this.marker.model?.isDrone()) {
        if (this.marker.model.getBatteryLevel() < 10) {
          classes.push('warning');
          classes.push('flashing');
        }
      }
      // Object specific
      if (this.marker.model?.isObject()) {
        // If unknown, flash
        if (this.marker.model.isUnknown()) {
          classes.push('flashing');
        }
      }
      if (this.$mapSystem.isSelected(this.marker.model)) {
        classes.push('active');
      }
      return classes.join(' ');
    }
  },
}
</script>