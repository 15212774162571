<template>
  <div class="camera-view row">
    <div class="collapsable left button">
      <button @click="toggleLeft" class="btn btn-primary">Indicators</button>
    </div>
    <div class="collapsable left hidden">
      <motor-status-component :drone="drone" />
    </div>
    <div class="col-12">
      <!-- Camera view goes here. -->
      <img v-if="cameraViewSrc" :src="'data:image/png;base64, ' + cameraViewSrc" alt="Camera View">
      <img v-else :src="require('@/assets/missing_image.png')" alt="No camera view available.">
    </div>
    <div class="collapsable right button">
      <button @click="toggleRight" class="btn btn-primary">Diagnostics</button>
    </div>
    <div class="collapsable right hidden">
      <!-- Diagnostics go here. -->
      <diagnostics-view :drone="drone" />
    </div>
  </div>
</template>

<script>

import MotorStatusComponent from "@/modals/ManualControl/MotorStatusComponent.vue";
import DiagnosticsView from "@/modals/ManualControl/Diagnostics.vue";

export default {
  name: 'CameraView',
  components: {MotorStatusComponent, DiagnosticsView},
  props: {
    cameraViewSrc: String,
    drone: Object,
  },
};

</script>

<style>
.camera-view img {
  max-width: 100%;
  max-height: 600px;
  border: 1px solid #ccc;
}

.camera-view {
  position: relative;
  display: block;
}

.camera-view .collapsable {
  position: absolute;
  top: 0;
  bottom: 0;
  display: block;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.camera-view .collapsable.left {
  left: 0;
}

.camera-view .collapsable.right {
  right: 0;
}

.camera-view .collapsable.hidden {
  display: none;
}

@media (max-width: 1920px) {
  .camera-view img {
    max-height: 400px;
  }
}

@media (max-width: 1280px) {
  .camera-view img {
    max-height: 300px;
  }
}

@media (max-width: 1024px) {
  .camera-view img {
    max-height: 200px;
  }
}
</style>