<template>
  <div class="container">
    <!-- Player view -->
    <div v-if="selectedData" class="player-container">
      <view-video :videoSrc="getDownloadUrl()" />
    </div>
    <!-- Drone data list -->
    <div class="list-container">
      <table class="table table-striped">
        <thead>
        <tr>
          <th class="id-col">ID</th>
          <th class="filename-col">Filename</th>
          <th class="record-started-col">Record Started At</th>
          <th class="duration-col">Duration</th>
          <th class="action-col">Action</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="data in droneData" :key="data.id">
          <td class="id-col">{{ data.id }}</td>
          <td class="filename-col">{{ data.filename }}</td>
          <td class="record-started-col">{{ data.record_started_at }}</td>
          <td class="duration-col">{{ calculateDuration(data.record_started_at, data.record_ended_at) }}</td>
          <td class="action-col">
            <button @click="viewData(data)" class="btn btn-primary mr-1">View</button>
            <button @click="downloadData(data.id, data.filename)" class="btn btn-secondary">Download</button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import ViewVideo from './DroneDataView/ViewVideo.vue';

export default {
  data() {
    return {
      droneData: [],
      selectedData: null,
    };
  },
  components: {
    ViewVideo,
  },
  props: {
    drone: Object,
  },
  created() {
    this.fetchDroneData();
  },
  methods: {
    getDownloadUrl() {
      return `/api/drones/${this.drone.uuid}/data/${this.selectedData.id}/download`;
    },
    fetchDroneData() {
      this.$http.get(`/api/drones/${this.drone.uuid}/data`).then((response) => {
        this.droneData = response.data;
      });
    },
    viewData(data) {
      this.selectedData = data;
    },
    downloadData(dataId, filename) {
      this.$http({
        url: `/api/drones/${this.drone.uuid}/data/${dataId}/download`,
        method: 'GET',
        responseType: 'blob', // important
      }).then((response) => {
        // Filename from response
        if (response.headers['Content-Disposition']) {
          filename = response.headers['Content-Disposition'].split('filename=')[1];
        }

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
    calculateDuration(start, end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const duration = endDate - startDate;
      const minutes = Math.floor(duration / 60000);
      const seconds = ((duration % 60000) / 1000).toFixed(0);
      return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
    }
  }
};
</script>

<style>
.popup-modal {
  max-height: 90%;
  overflow-y: hidden;
}

.popup-modal-body {
  overflow-y: hidden;
}

.list-container {
  max-height: 500px; /* Adjust based on your needs to fit the recordings list within the viewport */
  overflow-y: auto; /* Enables scrolling within the list container */
}

.table {
  width: 100%;
  table-layout: fixed;
}

.table thead th {
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 2; /* Ensure the header is above other content */
}

tbody {
  overflow-y: auto; /* Enables scrolling */
}

/* ID column */
.table .id-col {
  width: 50px; /* Fixed width */
}

/* Filename column */
.table .filename-col {
  min-width: 150px; /* Minimum width */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Record Started At column */
.table .record-started-col {
  width: 180px; /* Fixed width */
}

/* Duration column */
.table .duration-col {
  width: 100px; /* Fixed width */
}

/* Action column */
.table .action-col {
  width: 200px; /* Fixed width */
}
</style>
