<template>
  <table class="hotkey-legend">
    <thead>
      <th v-for="hotkey in hotkeys" :key="hotkey.key" :class="'hotkey' + calculateIsPressed(hotkey.key)"
          @mousedown="handleKey(hotkey.key)"
          @mouseup="handleKeyRelease"
          @touchstart.prevent="handleKey(hotkey.key)"
          @touchend.prevent="handleKeyRelease">{{ hotkey.display }}</th>
    </thead>
    <tbody>
      <tr>
        <td v-for="hotkey in hotkeys" :key="hotkey.key">
          <label class="hotkey-description">
            <info-message :message="hotkey.message" />
            {{ hotkey.description }}
          </label>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import InfoMessage from "@/components/Notification/InfoMessage.vue";

export default {
  name: 'HotkeyLegend',
  components: {InfoMessage},
  props: {
    drone: Object,
    droneControl: Object
  },
  data() {
    return {
      hotkeys: [
        { key: 'a', display: "A", description: 'Tilt left', message: 'Hold to tilt left' },
        { key: 'w', display: "W", description: 'Move forward', message: 'Hold to move forward' },
        { key: 's', display: "S", description: 'Move backward', message: 'Hold to move backward' },
        { key: 'd', display: "D", description: 'Tilt right', message: 'Hold to tilt right' },
        { key: 'q', display: "Q", description: 'Rotate left', message: 'Hold to rotate left' },
        { key: 'e', display: "E", description: 'Rotate right', message: 'Hold to rotate right' },
        { key: 'z', display: "Z", description: 'Increase VTOL', message: 'Hold to increase VTOL' },
        { key: 'c', display: "C", description: 'Decrease VTOL', message: 'Hold to decrease VTOL' },
        { key: 'x', display: "X", description: 'Reset VTOL', message: 'Hold to reset VTOL' },
        { key: 'ArrowUp', display: "↑", description: 'Increase altitude', message: 'Hold to increase altitude' },
        { key: 'ArrowDown', display: "↓", description: 'Decrease altitude', message: 'Hold to decrease altitude' }
      ],
      vtol: 0
    }
  },
  created() {
    // Key Release
    this.$globalEvents.onKeyPress(['w', 's', 'a', 'd', 'q', 'e', 'ArrowUp', 'ArrowDown'], this.handleKey)
    this.$globalEvents.onKeyRelease(['w', 's', 'a', 'd', 'q', 'e', 'ArrowUp', 'ArrowDown'], this.handleKeyRelease)
  },
  beforeUnmount() {
    this.$globalEvents.removeKeyEvents(['w', 's', 'a', 'd', 'q', 'e', 'ArrowUp', 'ArrowDown']);
  },
  methods: {
    calculateIsPressed(key) {
      const pressed = this.$globalEvents.getPressedKeys().includes(key)

      return pressed ? ' pressed' : ''
    },
    handleKeyRelease() {
      if (!this.$globalEvents.isComponentDisplayed(this) || !this.drone.manualControlEnabled()) return;

      // If nothing is pressed, reset the motors
      const pressedKeys = this.$globalEvents.getPressedKeys()
      if (pressedKeys.length === 0) {
        this.droneControl.resetMotors()
      }

      this.$emit('update-targets')
    },
    handleKey(key) {
      console.log(key)
      if (!this.$globalEvents.isComponentDisplayed(this) || !this.drone.manualControlEnabled()) return;

      if (key === 'w') {
        this.droneControl.droneControlAction('moveForward')
      } else if (key === 's') {
        this.droneControl.droneControlAction('moveBackward')
      } else if (key === 'a') {
        this.droneControl.droneControlAction('tiltLeft')
      } else if (key === 'd') {
        this.droneControl.droneControlAction('tiltRight')
      } else if (key === 'q') {
        this.droneControl.droneControlAction('rotateLeft')
      } else if (key === 'e') {
        this.droneControl.droneControlAction('rotateRight')
      } else if (key === 'z') {
        this.vtol = this.vtol + 1
        this.droneControl.droneControlAction('increaseVtol')
      } else if (key === 'c') {
        this.vtol = this.vtol - 1
        this.droneControl.droneControlAction('decreaseVtol')
      } else if (key === 'x') {
        this.vtol = 0
        this.droneControl.droneControlAction('resetVtol')
      } else if (key === 'ArrowUp') {
        this.droneControl.droneControlAction('increaseAltitude')
      } else if (key === 'ArrowDown') {
        this.droneControl.droneControlAction('decreaseAltitude')
      }
      this.$emit('update-targets')
    }
  }
};
</script>

<style>
.hotkey-legend-item {
  align-items: center;
  cursor: pointer;
}

.hotkey {
  font-size: 2rem;
  font-weight: bold;
  cursor: pointer;
}

.hotkey.pressed {
  color: red;
}

.hotkey-legend th, .hotkey-legend td {
  width: 10%;
}

.hotkey-description {
  font-size: 12px;
  text-align: center;
}
</style>