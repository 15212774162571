<template>
  <!-- base64 image, center div X -->
  <div style="display: flex; justify-content: center; cursor: pointer;">
    <img ref="imageHandle" :src="image ? 'data:image/png;base64, ' + image : require('@/assets/missing_image.png')" alt="Data image" class="img-thumbnail" />
    <div v-if="displayPosition === true">
      <!-- Display mouse position on image -->
      <div class="position-absolute">
        <span class="badge bg-primary">{{ mousePosition.x }}, {{ mousePosition.y }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    image: {
      type: String,
      required: true
    },
    displayPosition: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      mousePosition: {
        x: 0,
        y: 0
      }
    }
  },
  mounted() {
    this.$refs.imageHandle.addEventListener("mousemove", this.onMouseMove)
  },
  beforeUnmount() {
    this.$refs.imageHandle.removeEventListener("mousemove", this.onMouseMove)
  },
  methods: {
    onMouseMove(event) {
      this.mousePosition.x = event.offsetX
      this.mousePosition.y = event.offsetY
    }
  }
}
</script>