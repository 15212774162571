<template>
  <div class="motor-status">
    <div v-for="(motor, index) in drone.motors" :key="index" class="motor">
      <div class="motor-power" :style="{ backgroundColor: calculateColor(motor.getSpeed()) }">{{ motor.getSpeed() }}</div>
      <div class="motor-label">#{{ index + 1 }} - {{ motor.type }} Motor</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MotorStatusComponent',
  props: {
    drone: Object,
  },
  methods: {
    calculateColor(power) {
      const red = Math.floor(255 * power / 100);
      const green = 255 - red;
      return `rgb(${red}, ${green}, 0)`;
    },
  },
};
</script>

<style>
.motor-status {
  display: block;
  max-width: 192px;
}

.motor {
  width: 80px;
  float: left;
  text-align: center;
}

.motor-power {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: white;
  line-height: 40px;
  margin: 0 auto;
}

.motor-label {
  margin-top: 10px;
  font-size: 12px;
}


</style>