<template>
  <div class="modal-backdrop" v-if="showModal"></div>
  <div class="modal" tabindex="-1" role="dialog" v-if="showModal">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4>Training Data Entry</h4>
        </div>
        <div class="modal-body">
          <!-- Select element for model list -->
          <div class="row mb-1">
            <div class="col">
              <label for="model">
                Model
              </label>
            </div>
            <div class="col">
              <select id="model" v-model="model" @change="fetchStructure">
                <option v-for="(model, index) in models" :key="index">{{ model }}</option>
              </select>
            </div>
          </div>

          <!-- Display model fields for input -->
          <!-- Title -->
          <div class="row mb-1" v-if="structure.input.length > 0">
            <div class="col">
              <h5>Input</h5>
            </div>
          </div>

          <!-- Content -->
          <div class="row mb-1" v-for="field in structure.input" :key="field.key">
            <div class="col">
              <label :for="field.key">
                {{ field.name }}
                <!-- If image type, display image sizes -->
                <span v-if="field.type == 'image'">
                  (Width: {{ field.width }}, Height: {{ field.height }})
                </span>
              </label>
            </div>
            <div class="col">
              <!-- number input -->
              <input v-if="!field.type" :id="field.key" type="number" v-model="field.value" />
              <!-- text input -->
              <input v-else-if="field.type == 'text'" :id="field.key" type="text" v-model="field.value" />
              <!-- image input -->
              <input v-else-if="field.type == 'image'" :id="field.key" type="file" @change="onFileChange($event, field.key, 'input')" />
            </div>
          </div>

          <!-- Display model fields for output -->
          <!-- Title -->
          <div class="row mb-1" v-if="structure.output.length > 0">
            <div class="col">
              <h5>Output</h5>
            </div>
          </div>

          <!-- Content -->
          <div class="row mb-1" v-for="field in structure.output" :key="field.key">
            <div class="col">
              <label :for="field.key">
                {{ field.name }}
              </label>
            </div>
            <div class="col">
              <!-- number input -->
              <input v-if="!field.type" :id="field.key" type="number" v-model="field.value" />
              <!-- text input -->
              <input v-else-if="field.type == 'text'" :id="field.key" type="text" v-model="field.value" />
              <!-- image input -->
              <input v-else-if="field.type == 'image'" :id="field.key" type="file" @change="onFileChange($event, field.key, 'output')" />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button style="float:left;" @click="close">Close</button>
          <button @click="save" :disabled="model === null">Save</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showModal: Boolean
  },
  data() {
    return {
      uuid: '',
      password: '',
      models: [],
      model: null,
      structure: {
        input: [],
        output: []
      }
    };
  },
  created() {
    this.fetchModels();
  },
  methods: {
    generatedInputFields(inputData) {
      const result = [];
      // Loop through inputs
      inputData.forEach(field => {
        // If length is set and greater than 1
        if (field.length && field.length > 1) {
          // Loop through the length
          const mainName = field.name;
          for (let i = 0; i < field.length; i++) {
            let newField = {...field}; // Create a new object
            newField.name = `${mainName} ${i + 1}`;
            newField.key  = `${mainName}_${i + 1}`;
            result.push(newField); // Push the new object
          }
        } else {
          result.push(field);
        }
      });

      return result;
    },
    generatedOutputFields(outputData) {
      const result = [];
      // Loop through outputs
      outputData.forEach(field => {
        // If length is set and greater than 1
        if (field.length && field.length > 1) {
          // Loop through the length
          const mainName = field.name;
          for (let i = 0; i < field.length; i++) {
            let newField = {...field}; // Create a new object
            newField.name = `${mainName} ${i + 1}`; // Modify the new object
            newField.key  = `${mainName}_${i + 1}`;
            result.push(newField); // Push the new object
          }
        } else {
          result.push(field);
        }
      });

      return result;
    },
    fetchStructure() {
      if (this.model) {
        this.$trainingSystem.fetchStructure(this.model).then((response) => {
          this.structure = {
            input: this.generatedInputFields(response.data.data.input),
            output: this.generatedOutputFields(response.data.data.output)
          }
        });
      }
    },
    onFileChange(e, key, inputOutput) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0], key, inputOutput);
    },
    createImage(file, key, inputOutput) {
      let reader = new FileReader();
      let vm = this;
      reader.onload = (e) => {
        let img = new Image();
        img.onload = function() {
          let canvas = document.createElement('canvas');
          canvas.width = this.width;
          canvas.height = this.height;
          canvas.getContext('2d').drawImage(this, 0, 0, this.width, this.height);
          let dataURL = canvas.toDataURL('image/png');
          if (inputOutput === 'input') {
            vm.structure.input.forEach(field => {
              if (field.key === key) {
                field.value = dataURL;
              }
            });
          } else {
            vm.structure.output.forEach(field => {
              if (field.key === key) {
                field.value = dataURL;
              }
            });
          }
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    fetchModels() {
      this.$trainingSystem.fetchModels().then((response) => {
        this.models = response.data.data.models;
      });
    },
    close() {
      this.$emit('close-modal');
    },
    save() {
      this.$globalEvents.emit("loading-screen:show");
      this.$trainingSystem.addTrainingData(this.model, this.structure.input, this.structure.output).then(() => {
        this.$globalEvents.emit("training-data-updated");
        // Notification
        this.$globalEvents.emit("show-notification", "Training data added successfully", "success");
        this.$globalEvents.emit("loading-screen:hide");
      });
      this.$emit('close-modal');
    }
  }
};
</script>