<template>
  <button @click="refreshData" class="btn btn-primary float-right refresh-button">
    Refresh
  </button>
</template>

<script>
export default {
  name: 'RefreshButton',
  methods: {
    refreshData() {
      this.$emit('refresh-data');
    }
  }
};
</script>

