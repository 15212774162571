
// Class that handles drone markers and drone system
import Drone from "@/models/drone";

export default class DroneSystem {
    constructor() {
        this.drones = [];
        this.selectedDrone = null;
    }

    init(appHandle) {
        console.log("DroneSystem: Initialized");
        this.appHandle = appHandle;

        // Setup listeners
        this.appHandle.getSocket().listen('get-drones', (data) => {
            this.setDrones(data.data);
        });

        this.appHandle.getSocket().listen('get-drone', (data) => {
            this.updateOrAddDrone(new Drone(data.data));
        });

        this.appHandle.getSocket().listen('get-drone-image', (data) => {
            this.updateImage(data.data);
        });

        // Fetch drones from server interval
        this.fetchDrones();
        setInterval(() => {
            this.fetchDrones();
        }, 1000);
    }

    // Send shutdown command to drone
    shutdown(droneId) {
        // Send notification
        this.appHandle.getGlobalEvents().emit('show-notification', 'Shutting down drone...', 'info');
        this.appHandle.getSocket().sendEvent('shutdown', {id: droneId})
    }

    getLogs(id, callback) {
        // Fetch logs from the server
        this.appHandle.getHttp().get("/api/drones/" + id + "/logs").then(response => {
            callback(response.data)
        })
    }

    // Update or add drone
    updateOrAddDrone(drone) {
        let found = false;
        this.drones.forEach((item, index) => {
            if(item.id === drone.id) {
                this.drones[index] = drone;
                found = true;
            }
        });

        if (!found) {
            this.drones.push(drone);
        }

        // Trigger global event
        this.appHandle.getGlobalEvents().emit('drones-updated');
    }

    // Update drone image
    updateImage(data) {
        this.drones.forEach((item, index) => {
            if(item.id === data.id) {
                this.drones[index].image = data.image;
            }
        });
    }

    // Fetch drone
    fetchDrone(id) {
        // Fetch via socket
        try {
            this.appHandle.getSocket().sendEvent('get-drone', {id: id})
        } catch (error) {
            console.error(error);
        }
    }

    // Fetch drone image
    fetchDroneImage(id) {
        // Fetch via socket
        try {
            this.appHandle.getSocket().sendEvent('get-drone-image', {id: id})
        } catch (error) {
            console.error(error);
        }
    }

    // Update drone list
    fetchDrones() {
        // Fetch via socket
        try {
            this.appHandle.getSocket().sendEvent('get-drones')
        } catch (error) {
            console.error(error);
        }
    }

    // Add a drone to the drone system
    addDrone(drone) {
        this.drones.push(drone);
        // Send drone to server
        this.appHandle.getHttp().post('/api/drones', drone)
            .catch((error) => {
                console.error(error);
            })
    }

    // Remove a drone from the drone system
    deleteDroneById(drone) {
        // remove drone from drones array
        this.drones.forEach((item, index) => {
            if(item.id === drone.id) {
                this.drones.splice(index, 1);

                // Send delete request to server
                this.appHandle.getHttp().delete('/api/drones/' + drone.id)
            }
        });
    }

    // Set Drones
    setDrones(drones) {
        // Loop through and addOrUpdate drones
        drones.forEach((drone) => {
            const newDrone = new Drone(drone);
            this.updateOrAddDrone(newDrone);
            this.appHandle.getGlobalEvents().emit('drone-updated', newDrone);
        });

        // Trigger global event
        this.appHandle.getGlobalEvents().emit('drones-updated');
    }

    // Get drones
    getDrones() {
        if (this.drones.length > 0) {
            return this.drones;
        }
        return [];
    }

    // Get Active Drones
    getActiveDrones() {
        const result = []
        this.drones.forEach(drone => {
            if (!drone.isTimeout()) {
                result.push(drone)
            }
        })

        return result
    }

    // Get drone by id
    getDroneById(id) {
        return this.drones.find(d => d.id === id);
    }

    // Get drone distance from coordinates (in meters)
    getDistance(drone, lat, lng) {
        const  droneLat = drone.lat;
        const droneLng = drone.lng;
        const R = 6371e3; // metres
        const φ1 = droneLat * Math.PI/180; // φ, λ in radians
        const φ2 = lat * Math.PI/180;
        const Δφ = (lat-droneLat) * Math.PI/180;
        const Δλ = (lng-droneLng) * Math.PI/180;
        const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
          Math.cos(φ1) * Math.cos(φ2) *
          Math.sin(Δλ/2) * Math.sin(Δλ/2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

        const d = R * c; // in metres

        // Rounded to 2 decimal places
        return Math.round(d * 100) / 100;
    }

    // Send command to drone
    sendCommand(droneId, command, data= {}) {
        this.appHandle.getSocket().sendEvent('send-command', {id: droneId, command: command, data: data})
    }

    updateState(drone, newState=null) {
        if (newState === null) {
            newState = drone.getDroneState()
        }
        this.appHandle.getSocket().sendEvent("update-state", {
            id: drone.id,
            data: newState,
            command: "update-state"
        });

        // Trigger global event
        this.appHandle.getGlobalEvents().emit('drones-updated');
    }

    manualControl(drone, newState=null) {
        if (newState === null) {
            newState = drone.getDroneState()
        }
        this.appHandle.getSocket().sendEvent("manual-control", {
            id: drone.id,
            data: newState,
            command: "manual-control"
        });

        // Trigger global event
        this.appHandle.getGlobalEvents().emit('drones-updated');
    }
}