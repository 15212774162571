
export default class Camera {

    constructor(payload) {
        this.hasCamera = false
        this.canMoveX = false
        this.canMoveY = false
        this.canZoom = false
        this.recording = false
        if (payload) {
            this.hasCamera = true
            if (payload["canMoveX"]) {
                this.canMoveX = true
            }
            if (payload["canMoveY"]) {
                this.canMoveY = true
            }
            if (payload["canZoom"]) {
                this.canZoom = true
            }
            if (payload["recording"]) {
                this.recording = true
            }
        }
    }

    isCamera() {
        return true
    }

    canMove() {
        return this.canMoveX || this.canMoveY
    }

    isRecording() {
        return this.recording
    }

    canZoom() {
        return this.canZoom
    }

    hasCamera() {
        return this.hasCamera
    }

    setTargetRotation(rotationX, rotationY) {
        console.log("Setting target rotation to: " + rotationX + ", " + rotationY)
    }

    setTargetZoom(zoom) {
        console.log("Setting target zoom to: " + zoom)
    }
}