// GoogleMaps API: https://developers.google.com/maps/documentation/javascript/tutorial
// Class that handles GoogleMaps
export default class MapSystem {
    constructor(appHandle) {
        this.appHandle = appHandle;
        this.markers = [];
        this.map = null;
        this.mapOptions = {
            zoom: 15,
            center: {lat: 59.45279543191348, lng: 24.71555244417669},
            mapTypeId: 'satellite',
            disableDefaultUI: true,
            zoomControl: true,
            mapTypeControl: true,
            scaleControl: true,
            streetViewControl: true,
            fullscreenControl: true,
        };
    }

    init(handle) {
        console.log("MapSystem: Initialized");
        this.map = handle;
    }

    isSelected(marker) {
        if (marker.MY_CLASS === "Drone") {
            return this.appHandle.getDroneSystem().selectedDrone && this.appHandle.getDroneSystem().selectedDrone.id === marker.id
        } else if (marker.MY_CLASS === "DetectedObject") {
            return this.appHandle.getObjectSystem().selectedObject && this.appHandle.getObjectSystem().selectedObject.id === marker.id
        }
    }

    findProperObjectFromMarker(marker) {
        const markerId = marker.id
        const drones = this.appHandle.getDroneSystem().getDrones()
        const objects = this.appHandle.getObjectSystem().getObjects()
        let selectedObject = null
        if (marker.type === 'drone') {
            selectedObject = drones.find(drone => drone.id === markerId)
        } else if (marker.type === 'object') {
            selectedObject = objects.find(object => object.id === markerId)
        }
        if (!selectedObject) {
            console.error('Could not find object with id ' + markerId)
            return
        }
        return selectedObject
    }

    reloadObject(object) {
        let newObject = null
        if (object.type === 'drone') {
            newObject = this.appHandle.getDroneSystem().getDroneById(object.id)
        } else if (object.type === 'object') {
            newObject = this.appHandle.getObjectSystem().getObjectById(object.id)
        }

        return newObject
    }

    getLines() {
        // Create lines between drones and their tracked objects.
        const lines = [];
        this.appHandle.getDroneSystem().getDrones().forEach((marker) => {
            if (marker.getTrackedObject()) {
                const track = this.appHandle.getObjectSystem().getObjectById(marker.getTrackedObject());
                if (track?.id) {
                    lines.push({
                        from: {lat: marker.lat, lng: marker.lng},
                        to: {lat: track.lat, lng: track.lng},
                        color: "#ad0000"
                    });
                }
            }
        });

        // Create lines between masters and slaves
        this.appHandle.getDroneSystem().getDrones().forEach((marker) => {
            if (!marker.isMaster()) {
                const master = this.appHandle.getDroneSystem().getDroneById(marker.getMasterId());
                if (master?.id) {
                    lines.push({
                        from: {lat: marker.lat, lng: marker.lng},
                        to: {lat: master.lat, lng: master.lng},
                        color: "#0000ad"
                    });
                }
            }
        });

        return lines;
    }

    getMapOptions() {
        return this.mapOptions;
    }

    // Add a marker to the map
    addMarker(marker) {
        this.markers.push(marker);
    }

    // Remove a marker from the map
    deleteMarkerById(markerId) {
        // remove marker from markers array
        this.markers.forEach((item, index) => {
            if (item.id === markerId) {
                this.markers.splice(index, 1);
            }
        });
    }

    // Delete by createdBy
    deleteMarkerByCreatedBy(createdBy) {
        const newMarkers = [];
        this.markers.forEach((item) => {
            if (item.createdBy !== createdBy) {
                newMarkers.push(item);
            }
        });
        this.markers = newMarkers;
    }

    // Get markers
    getMarkers() {
        return this.markers;
    }

    // Get marker by id
    getMarkerById(id) {
        return this.markers.find(m => m.id === id);
    }

    // Get marker distance from coordinates (in meters)
    getDistance(marker, lat, lng) {
        const markerLat = marker.lat;
        const markerLng = marker.lng;
        const R = 6371e3; // metres
        const φ1 = markerLat * Math.PI / 180; // φ, λ in radians
        const φ2 = lat * Math.PI / 180;
        const Δφ = (lat - markerLat) * Math.PI / 180;
        const Δλ = (lng - markerLng) * Math.PI / 180;
        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
            Math.cos(φ1) * Math.cos(φ2) *
            Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        const d = R * c; // in metres
        return d;
    }
}