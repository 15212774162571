<template>
  <!-- Image Modal -->
  <div class="modal" tabindex="-1" role="dialog" v-if="showModal">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ getTitle() }}</h5>
          <button type="button" class="close" @click="$emit('close-modal')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <img v-if="getCorrectImage()" :src="'data:image/png;base64, ' + getCorrectImage()" alt="Data image" style="width: 100%;" />
          <img v-else src="@/assets/missing_image.png" alt="Missing image" style="width: 100%;" />
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" @click="$emit('close-modal')">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    selectedObject: {
      type: Object,
      required: false
    },
    selectedImage: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      image: null
    }
  },
  created() {
    this.updateImage()

    setTimeout(this.updateImage, 1000)
  },
  methods: {
    getCorrectImage() {
      if (this.selectedImage) {
        return this.selectedImage
      }
      return this.image
    },
    updateImage() {
      // If component not visible, return
      if (!this.showModal) {
        setTimeout(this.updateImage, 1000)
        return
      }
      setTimeout(this.updateImage, 1000)

      // If drone, update drone image
      if (this.selectedObject && this.selectedObject.MY_CLASS === 'Drone') {
        this.$droneSystem.fetchDroneImage(this.selectedObject.id)
        this.image = this.selectedObject.getImage()
      }
    },
    getTitle() {
      if (!this.selectedObject) {
        return 'No Title'
      }
      if (this.selectedObject.MY_CLASS === 'Drone') {
        return 'Drone image'
      } else if (this.selectedObject.MY_CLASS === 'DetectedObject') {
        return 'Detected object image'
      } else {
        return 'Unknown image'
      }
    },
  }
}
</script>