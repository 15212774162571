<template>
  <div>
    <div v-for="(item, index) in inputData" :key="index" @click="clickLabel(index, item)" style="float:left;">
      <div v-if="item.type === 'image'">
        <data-type-image :image="item.value" :display-position="true" />
      </div>
      <span v-else class="badge bg-primary">
        {{ inputDataLabels[index] }}: {{ item.value }}
      </span>
    </div>
  </div>
</template>

<script>
import DataTypeImage from "@/components/TrainingTool/DataTypeImage.vue";
export default {
  components: {DataTypeImage},
  props: {
    inputData: {
      type: Array,
      required: true
    },
    inputDataLabels: {
      type: Array,
      required: true
    }
  },
  methods: {
    clickLabel(index, label) {
      this.$emit('click-label', {
        index: index,
        value: label
      })
    }
  }
}
</script>