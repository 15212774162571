<template>
  <!-- A popup window that displays current selected object -->
  <div class="object-view" v-if="selectedObject.id">
    <div class="object-view-header">
      <div class="object-view-header-title">
        <div class="object-view-header-title-icon">
          <img :src="selectedObject.getIcon()" />
        </div>
        <div class="object-view-header-title-text">
          {{ selectedObject.MY_CLASS }}: #{{ selectedObject.id }}
        </div>
      </div>
      <div class="object-view-header-close">
        <button @click="closeObjectView">X</button>
      </div>
    </div>
    <div class="object-view-content">
      <div class="object-view-content-info">
        <div class="object-view-content-info-item">
          <div class="object-view-content-info-item-title">
            <info-message message="The type of the marker. (drone, object)" />
            Type
          </div>
          <div class="object-view-content-info-item-value">
            <span class="badge bg-primary">{{ selectedObject.type }}</span>
          </div>
        </div>
        <div class="object-view-content-info-item">
          <div class="object-view-content-info-item-title">Latitude</div>
          <div class="object-view-content-info-item-value">
            <span class="badge bg-primary">{{ selectedObject.lat }}</span>
          </div>
        </div>
        <div class="object-view-content-info-item">
          <div class="object-view-content-info-item-title">Longitude</div>
          <div class="object-view-content-info-item-value">
            <span class="badge bg-primary">{{ selectedObject.lng }}</span>
          </div>
        </div>
        <div v-if="selectedObject.isDrone()">
          <drone-view-data :selected-object="selectedObject" />
        </div>
        <div v-else>
          <classify-buttons v-if="selectedObject.MY_CLASS === 'DetectedObject'" :selected-object="selectedObject" />
        </div>
        <!-- Image -->
        <div class="row mt-2" v-if="selectedObject">
          <data-type-image :image="selectedObject.getImage()" @click="showModal = true" />
          <view-image-modal :selected-object="selectedObject" :show-modal="showModal" @close-modal="showModal = false" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DroneViewData from "@/components/Drone/DroneViewData.vue";
import DataTypeImage from "@/components/TrainingTool/DataTypeImage.vue";
import ViewImageModal from "@/modals/ViewImageModal.vue";
import ClassifyButtons from "@/components/DetectedObject/ClassifyButtons.vue";
import InfoMessage from "@/components/Notification/InfoMessage.vue";

export default {
  components: {InfoMessage, ClassifyButtons, ViewImageModal, DataTypeImage, DroneViewData},
  props: {
    selectedObject: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      showModal: false
    }
  },
  methods: {
    closeObjectView() {
      this.$emit('close-object-view')
    }
  }
}
</script>

<style src="../assets/css/objectView.css"></style>