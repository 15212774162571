<template>
  <div class="row mb-5">
    <div class="col-3" id="status">
      <div class="row">
        <div class="col-4">Status</div>
        <div class="col-8">
          <select id="status" style="width:100%;" v-model="statusFilter" @change="updateFilters()">
            <option value="all">All</option>
            <option value="trained">Trained</option>
            <option value="training">Training</option>
            <option value="completed">Completed</option>
            <option value="pending">Pending</option>
          </select>
        </div>
      </div>
    </div>

    <div class="col-3" id="model">
      <div class="row">
        <div class="col-4">Model</div>
        <div class="col-8">
          <select id="status" style="width:100%;" v-model="modelFilter" @change="updateFilters()">
            <option value="all">All</option>
            <option v-for="model in $trainingSystem.models" v-bind:key="model.key" :value="model.key">{{ model.label }}</option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      statusFilter: "all",
      modelFilter: "all"
    }
  },
  methods: {
    updateFilters() {
      this.$emit('update-filters', {
        status: this.statusFilter,
        model: this.modelFilter
      })
    }
  }
}
</script>