<template>
  <!-- Component that plays a h264 video -->
  <video controls v-if="videoBlobUrl">
    <source :src="videoBlobUrl" type="video/mp4">
    Your browser does not support the video tag.
  </video>
  <div v-else>Loading video...</div>
</template>

<script>
export default {
  props: {
    videoSrc: String,
  },
  data() {
    return {
      videoBlobUrl: null,
    };
  },
  watch: {
    videoSrc: {
      immediate: true,
      handler(newSrc) {
        this.loadVideo(newSrc);
      }
    }
  },
  methods: {
    loadVideo(src) {
      this.$globalEvents.emit("loading-screen:show");
      this.videoBlobUrl = null; // Reset when source changes
      this.$http({
        url: src,
        method: 'GET',
        responseType: 'blob', // Important for handling binary data
      }).then(response => {
        const blobUrl = window.URL.createObjectURL(new Blob([response.data], { type: 'video/mp4' }));
        this.videoBlobUrl = blobUrl;
        this.$globalEvents.emit("loading-screen:hide");
      }).catch(error => {
        console.error('Error loading video:', error);
        this.videoBlobUrl = null;
        this.$globalEvents.emit("loading-screen:hide");
      });
    }
  },
  beforeUnmount() {
    // Clean up the object URL to avoid memory leaks
    if (this.videoBlobUrl) {
      window.URL.revokeObjectURL(this.videoBlobUrl);
    }
  }
};
</script>
