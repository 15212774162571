<template>
  <div class="settings-popup-modal" v-if="showModal">
    <div class="popup-modal modal-l">
      <div class="popup-modal-header">
        <div class="title">Settings</div>
        <div class="close" @click="close">X</div>
      </div>
      <div class="popup-modal-body">
        <div class="form">
          <!-- Title: Drone settings -->
          <div class="form-row">
            <div class="title">Drone settings</div>
          </div>
          <!-- Setting: Auto find master -->
          <div class="form-row">
            <label for="auto-find-master">
              <info-message message="If enabled, the drone will automatically find a master drone when it doesn't have one." />
              Auto find master
            </label>
            <input id="auto-find-master" type="checkbox" v-model="autoFindMaster" />
          </div>
          <!-- Setting: Continuous Training -->
          <div class="form-row">
            <label for="continuous-training">
              <info-message message="If enabled, the drone will continuously self-train its AI model." />
              Continuous Training
            </label>
            <input id="continuous-training" type="checkbox" v-model="continuousTraining" />
          </div>
          <!-- Setting: Data collection -->
          <div class="form-row">
            <label for="data-collection">
              <info-message message="If enabled, the drone will collect data for training its AI model." />
              Data collection
            </label>
            <input id="data-collection" type="checkbox" v-model="dataCollection" />
          </div>
          <!-- Setting: AI Motor Control -->
          <div class="form-row">
            <label for="ai-motor-control">
              <info-message message="If enabled, the drone will use its AI model to control its motors." />
              AI Motor Control
            </label>
            <input id="ai-motor-control" type="checkbox" v-model="aiMotorControl" />
          </div>
          <!-- Setting: Signal loss behaviour (select) -->
          <div class="form-row">
            <label for="signal-loss-behaviour">
              <info-message message="The behaviour of the drone when it loses signal." />
              Signal loss behaviour
            </label>
            <select id="signal-loss-behaviour" v-model="signalLossBehaviour">
              <option value="return-to-home">Return to home</option>
              <option value="hover">Continue</option>
              <option value="land">Land</option>
            </select>
          </div>
          <!-- Setting: Low battery behaviour (select) -->
          <div class="form-row">
            <label for="low-battery-behaviour">
              <info-message message="The behaviour of the drone when its battery is low." />
              Low battery behaviour
            </label>
            <select id="low-battery-behaviour" v-model="lowBatteryBehaviour">
              <option value="return-to-home">Return to home</option>
              <option value="land">Land</option>
            </select>
          </div>
          <!-- Setting: VTOL Enabled -->
          <div class="form-row">
            <label for="vtol-enabled">
              <info-message message="If enabled, the drone will be able to switch between quadcopter and plane mode." />
              VTOL Enabled
            </label>
            <input id="vtol-enabled" type="checkbox" v-model="vtolEnabled" />
          </div>
          <!-- Setting: Wifi SSID -->
          <div class="form-row">
            <label for="wifi-ssid">
              <info-message message="The SSID of the drone's wifi network." />
              Wifi SSID
            </label>
            <input id="wifi-ssid" type="text" v-model="wifiSsid" />
          </div>
          <!-- Setting: Wifi password -->
          <div class="form-row">
            <label for="wifi-password">
              <info-message message="The password of the drone's wifi network." />
              Wifi password
            </label>
            <input id="wifi-password" type="password" v-model="wifiPassword" />
          </div>
        </div>
      </div>
      <div class="popup-modal-footer">
        <button @click="save">Save</button>
        <button style="float:left;" class="btn-secondary" @click="close">Close</button>
      </div>
    </div>
  </div>
</template>
<script>
import InfoMessage from "@/components/Notification/InfoMessage.vue";

export default {
  components: {InfoMessage},
  data() {
    return {
      autoFindMaster: false,
      continuousTraining: false,
      dataCollection: false,
      aiMotorControl: false,
      signalLossBehaviour: "return-to-home",
      lowBatteryBehaviour: "return-to-home",
      vtolEnabled: false,
      wifiPassword: "",
      wifiSsid: "",
    };
  },
  props: {
    showModal: Boolean,
    drone: Object,
  },
  created() {
    this.updateData();
  },
  watch: {
    showModal: function (val) {
      if (val) {
        this.updateData();
      }
    },
  },
  methods: {
    updateData() {
      this.autoFindMaster = this.drone.configuration.autoFindMaster;
      this.continuousTraining = this.drone.configuration.continuousTraining;
      this.dataCollection = this.drone.configuration.dataCollection;
      this.aiMotorControl = this.drone.configuration.aiMotorControl;
      this.signalLossBehaviour = this.drone.configuration.signalLossBehaviour;
      this.lowBatteryBehaviour = this.drone.configuration.lowBatteryBehaviour;
      this.vtolEnabled = this.drone.configuration.vtolEnabled;
      this.wifiSsid = this.drone.configuration.wifiSsid;
      this.wifiPassword = this.drone.configuration.wifiPassword;
    },
    close() {
      this.$emit("close-modal");
    },
    save() {
      // Update drone configuration in droneSystem
      this.$droneSystem.fetchDrone(this.drone.id)

      // Save settings to drone
      this.$http
        .post(
          "/api/drones/" + this.drone.id + "/settings",
          {
            settings: {
              autoFindMaster: this.autoFindMaster,
              continuousTraining: this.continuousTraining,
              dataCollection: this.dataCollection,
              aiMotorControl: this.aiMotorControl,
              signalLossBehaviour: this.signalLossBehaviour,
              lowBatteryBehaviour: this.lowBatteryBehaviour,
              vtolEnabled: this.vtolEnabled,
              wifiSsid: this.wifiSsid,
              wifiPassword: this.wifiPassword,
            }
          }
        )
        .then(() => {
          this.$emit("close-modal");

          this.$globalEvents.emit("show-notification", "Settings saved", "success");
        })
        .catch((error) => {
          console.log(error);
          this.$globalEvents.emit("show-notification", "Error saving settings", "error");
        });
    },
  },
};
</script>
<style src="../assets/css/settings-modal.css"></style>
