import Base from './base';

/**
 * Object model
 */
export default class DetectedObject extends Base {
    MY_CLASS = "DetectedObject";

    // Types of objects: friendly, target, unknown
    TYPES = ["friendly", "target", "unknown"];

    constructor(data) {
        super(data);
        this.id = data.id;
        this.lat = data.lat;
        this.lng = data.lng;
        this.type = data.type;
        this.payload = data.payload;
    }

    getIcon() {
        if (this.type == "friendly") {
            return require("../assets/icons/object/object_friendly.png")
        } else if (this.type == "target") {
            return require("../assets/icons/object/object_target.png")
        }
        return require("../assets/icons/object/object_unknown.png")
    }

    isTimeout() {
        return false
    }

    isUnknown() {
        return this.type === "unknown"
    }
}