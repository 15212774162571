<template>
  <div style="float:left;margin-right: 10px;" @mouseover="this.$globalEvents.emit('show-popup', $event, this.message)" @mouseout="this.$globalEvents.emit('hide-popup')">
    <!-- question mark -->
    <i class="fas fa-question-circle"></i>
  </div>
</template>

<script>
export default {
  name: 'InfoMessage',
  props: {
    message: {
      type: String,
      required: true
    }
  },
}
</script>

  <!-- Add pointer cursor to the question mark icon -->
  <style scoped>
  .fas.fa-question-circle {
    cursor: pointer;
  }
  </style>