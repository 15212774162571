<template>
  <div class="row" style="width:100%;">
    <div class="col d-flex justify-content-center align-items-center">
      <div class="card" style="min-width: 600px;">
        <div class="card-body">
          <form @submit.prevent="searchArticles">
            <div class="mb-3">
              <label for="search" class="form-label">Search for help articles</label>
              <input type="text" class="form-control" id="search" v-model="search" placeholder="Enter search term">
            </div>
            <button type="submit" class="btn btn-primary">Search</button>
          </form>
        </div>
        <!-- Content of selected article -->
        <div class="row" v-if="selected">
          <div class="col article-content">
            <span>{{ selected.content }}</span>
          </div>
        </div>
        <!-- No results found -->
        <div class="row" v-if="suggestedArticles.length === 0 && search !== '' && searchPerformed">
          <div class="col">
            <h5>No results found</h5>
          </div>
        </div>
        <!-- List -->
        <ul class="help-article-list list-group list-group-flush">
          <li class="list-group-item" v-for="(article, index) in suggestedArticles" :key="index">
            <h5 @click="selectArticle(article)">{{ article.title }}</h5>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selected: null,
      search: '',
      suggestedArticles: [],
      searchPerformed: false,
    }
  },
  methods: {
    selectArticle(article) {
      this.selected = article
    },
    searchArticles() {
      this.selected = null
      this.searchPerformed = true
      this.$globalEvents.emit("loading-screen:show"); // Show loading screen
      this.$http.post('/api/help/suggested-articles', {
        search: this.search
      }).then((response) => {
        this.suggestedArticles = response.data.data
        this.$globalEvents.emit("loading-screen:hide"); // Hide loading screen
      }).catch((error) => {
        console.error(error);
        this.$globalEvents.emit("loading-screen:hide"); // Hide loading screen in case of error
      })
    }
  }
}
</script>

<style src="../assets/css/help.css"></style>