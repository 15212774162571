<template>
  <!-- Drone class -->
  <div class="object-view-content-info-item" v-if="selectedObject.class">
    <div class="object-view-content-info-item-title">
      <info-message message="The class of the drone. The class is used to determine the role of the drone in the swarm. (ie assault, scout)" />
      Class
    </div>
    <div class="object-view-content-info-item-value">
      <span class="badge bg-primary">{{ selectedObject.class }}</span>
    </div>
  </div>
  <!-- Altitude -->
  <div v-if="selectedObject.alt" class="object-view-content-info-item">
    <div class="object-view-content-info-item-title">
      <info-message message="The altitude of the drone in meters." />
      Altitude
    </div>
    <div class="object-view-content-info-item-value">
      {{ selectedObject.alt }}
    </div>
  </div>
  <!-- Slave count -->
  <div v-if="selectedObject.isMaster() && selectedObject.getSlaves().length" class="object-view-content-info-item">
    <div class="object-view-content-info-item-title">
      <info-message message="The number of slave drones connected to this master drone. Slave drones are the hands of the swarm, masters give orders." />
      Slave drones
    </div>
    <div class="object-view-content-info-item-value">
      {{ selectedObject.getSlaves().length }}
    </div>
  </div>
  <!-- Current script (selectbox) -->
  <div v-if="selectedObject.isMaster()" class="object-view-content-info-item">
    <div class="object-view-content-info-item-title">
      <info-message message="The current script that the master drone is running. The script can be changed by selecting a new script from the dropdown." />
      Current script
    </div>
    <div class="object-view-content-info-item-value">
      <select v-model="currentScript" @change="changeScript">
        <option v-for="script, idx in scripts" v-bind:key="idx" :value="script">{{ script }}</option>
      </select>
    </div>
  </div>
  <!-- Master info -->
  <div v-if="!selectedObject.isMaster() && selectedObject.getMasterId()">
    <div class="object-view-content-info-item">
      <div class="object-view-content-info-item-title">Master drone</div>
      <div class="object-view-content-info-item-value">
        {{ selectedObject.getMasterId() }}
      </div>
    </div>
  </div>
  <!-- Distance from master -->
  <div v-if="!selectedObject.isMaster() && selectedObject.getMasterId()">
    <div class="object-view-content-info-item">
      <div class="object-view-content-info-item-title">Distance from master</div>
      <div class="object-view-content-info-item-value">
        {{ getDistanceFromMaster() }} m
      </div>
    </div>
  </div>
  <!-- Payload data -->
  <div v-if="selectedObject.payloads.length > 0">
    <div class="object-view-content-info-item">
      <div class="object-view-content-info-item-title">Payloads</div>
      <div class="object-view-content-info-item-value">
        <ul>
          <li v-for="payload, idx in selectedObject.payloads" v-bind:key="idx" class="float-left mr-1">
            <span class="badge bg-primary">{{ payload.type }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Drone Settings modal -->
  <div>
    <div class="object-view-content-info-item">
      <div class="row p-2">
        <div class="col">
          <drone-settings-modal :show-modal="showDroneSettingsModal" :drone="selectedObject" @close-modal="closeDroneSettingsModal" />
          <button class="color-blue" @click="openDroneSettingsModal">Settings</button>
        </div>
        <div class="col">
          <drone-info-modal :drone="selectedObject" :show-modal="showDroneInfoModal" @close-modal="showDroneInfoModal = false" />
          <button class="color-orange" @click="showDroneInfoModal = true">Data</button>
        </div>
        <div class="col" v-if="!selectedObject.isTimeout()">
          <drone-control-modal :drone-id="selectedObject.id" :show-modal="showControlModal" @close-modal="showControlModal = false" />
          <button class="color-green" @click="showControlModal = true">Control</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Timeout message -->
  <div v-if="selectedObject.isTimeout()">
    <div class="object-view-content-info-item">
      <div class="object-view-content-info-item-value">
        <span class="badge bg-warning" :title="'Last seen: ' + selectedObject.lastSeen() + 's ago' ">Connection lost</span>
      </div>
    </div>
  </div>
  <!-- Script specific area -->
  <div v-if="selectedObject.isMaster() && selectedObject.payload['currentScript']">
    <circle-script v-if="selectedObject.payload['currentScript'] === 'circle'" />
    <path-script v-if="selectedObject.payload['currentScript'] === 'path'" />
    <track-script v-if="selectedObject.payload['currentScript'] === 'track'" />
    <fire-seeding-script v-if="selectedObject.payload['currentScript'] === 'fireSeeder'" />
  </div>
</template>
<script>
import CircleScript from "@/components/Scripts/Circle/CircleScript.vue";
import PathScript from "@/components/Scripts/Path/PathScript.vue";
import TrackScript from "@/components/Scripts/Track/TrackScript.vue";
import FireSeedingScript from "@/components/Scripts/FireSeeding/FireSeedingScript.vue";
import DroneSettingsModal from "@/modals/DroneSettingsModal.vue";
import DroneInfoModal from "@/modals/DroneInfoModal.vue";
import DroneControlModal from "@/modals/DroneControlModal.vue";
import InfoMessage from "@/components/Notification/InfoMessage.vue";

export default {
  name: 'ObjectViewData',
  components: {InfoMessage, DroneInfoModal, CircleScript, PathScript, TrackScript, DroneSettingsModal, DroneControlModal, FireSeedingScript},
  props: {
    selectedObject: {
      type: Object,
      required: true
    }
  },
  created() {
    if (this.selectedObject && this.selectedObject.payload) {
      this.currentScript = this.selectedObject.payload['currentScript'];
    } else {
      this.currentScript = null;
    }
  },
  watch: {
    selectedObject: function (newVal) {
      if (newVal && newVal.payload) {
        this.currentScript = newVal.payload['currentScript'];
      } else {
        this.currentScript = null;
      }
    }
  },
  data() {
    return {
      currentScript: 'circle',
      showDroneSettingsModal: false,
      showDroneInfoModal: false,
      showControlModal: false,
      scripts: [
        'circle',
        'path',
        'track',
        'fireSeeder'
      ]
    }
  },
  methods: {
    openDroneSettingsModal() {
      this.showDroneSettingsModal = true
    },
    closeDroneSettingsModal() {
      this.showDroneSettingsModal = false
    },
    changeScript() {
      // Close script view
      this.$globalEvents.emit('closeScriptView')
      const object = this.selectedObject
      object.payload['currentScript'] = this.currentScript
      this.$droneSystem.updateState(object)
      // Open script view
      this.$globalEvents.emit('openScriptView')
    },
    getDistanceFromMaster() {
      // Round to .2
      const master = this.$droneSystem.getDroneById(this.selectedObject.getMasterId())
      if (master) {
        return Math.round(this.$droneSystem.getDistance(master, this.selectedObject.lat, this.selectedObject.lng) * 100) / 100
      }
      return 0
    },
  }
}
</script>