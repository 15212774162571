<template>
  <div class="settings-popup-modal" v-if="showModal">
    <div class="popup-modal">
      <div class="popup-modal-header">
        <div class="title">Settings</div>
        <div class="close" @click="close">X</div>
      </div>
      <div class="popup-modal-body">
        <!-- Bootstrap tabs -->
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="hive-tab" data-bs-toggle="tab" data-bs-target="#hive" type="button" role="tab" aria-controls="hive" aria-selected="true">Hive</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="user-tab" data-bs-toggle="tab" data-bs-target="#user" type="button" role="tab" aria-controls="user" aria-selected="false">User</button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="hive" role="tabpanel" aria-labelledby="hive-tab">
            <!-- Rest of your Hive settings form -->
              <div class="form">
                <!-- Setting: Hive enabled -->
                <div class="form-row">
                  <label for="hive-enabled">
                    <info-message message="Enable hive system for all of your drones." />
                    Hive enabled
                  </label>
                  <input id="hive-enabled" type="checkbox" v-model="hiveEnabled" />
                </div>
                <!-- Setting: Shared computing -->
                <div class="form-row">
                  <label for="shared-computing">
                    <info-message message="Enable shared computing for all of your drones." />
                    Shared computing
                  </label>
                  <input id="shared-computing" type="checkbox" v-model="sharedComputing" />
                </div>
                <!-- Setting: Auto classification -->
                <div class="form-row">
                  <label for="auto-classification">
                    <info-message message="If enabled, the drone will automatically classify objects in its environment." />
                    Auto classification
                  </label>
                  <input id="auto-classification" type="checkbox" v-model="autoClassification" />
                </div>
                <!-- Setting: Auto environment mapping -->
                <div class="form-row">
                  <label for="auto-environment-mapping">
                    <info-message message="If enabled, the drone will automatically map its environment. See Digital Drone for more reference." />
                    Auto environment mapping
                  </label>
                  <input id="auto-environment-mapping" type="checkbox" v-model="autoEnvironmentMapping" />
                </div>
                <!-- Setting: Auto Investigation -->
                <div class="form-row">
                  <label for="auto-investigation">
                    <info-message message="If enabled, the drone will automatically investigate unknown objects." />
                    Auto Investigation
                  </label>
                  <input id="auto-investigation" type="checkbox" v-model="autoInvestigation" />
                </div>
                <!-- Setting: Auto share data -->
                <div class="form-row">
                  <label for="auto-share-data">
                    <info-message message="If enabled, the drone will automatically share its data with other drones." />
                    Auto share data
                  </label>
                  <input id="auto-share-data" type="checkbox" v-model="autoShareData" />
                </div>
              </div>
          </div>
          <div class="tab-pane fade" id="user" role="tabpanel" aria-labelledby="user-tab">
            <!-- User settings -->
              <!-- Setting: Change password -->
              <div class="form-row">
                <label for="change-password">Change password</label>
                <input id="change-password" type="password" v-model="changePassword" />
              </div>
              <!--- Setting: Confirm password -->
              <div class="form-row">
                <label for="confirm-password">Confirm password</label>
                <input id="confirm-password" type="password" v-model="confirmPassword" />
              </div>
            </div>
          </div>
      </div>
      <div class="popup-modal-footer">
        <button @click="save">Save</button>
        <button style="float:left;" @click="close">Close</button>
      </div>
    </div>
  </div>
</template>
<script>
import InfoMessage from "@/components/Notification/InfoMessage.vue";

export default {
  components: {InfoMessage},
  data() {
    return {
      hiveEnabled: false,
      sharedComputing: false,
      autoClassification: false,
      autoEnvironmentMapping: false,
      autoInvestigation: false,
      autoShareData: false,
      changePassword: "",
      confirmPassword: "",
    };
  },
  props: {
    showModal: Boolean,
  },
  methods: {
    close() {
      this.$emit("close-settings-modal");
    },
    save() {
      // Password check
      if (this.changePassword !== this.confirmPassword) {
        this.$globalEvents.emit("show-notification", "Passwords do not match", "error");
        return;
      }
      this.$http
        .post("/api/settings", {
          hiveEnabled: this.hiveEnabled,
          sharedComputing: this.sharedComputing,
          autoClassification: this.autoClassification,
          autoEnvironmentMapping: this.autoEnvironmentMapping,
          autoInvestigation: this.autoInvestigation,
          autoShareData: this.autoShareData,
          changePassword: this.changePassword,
          confirmPassword: this.confirmPassword,
        })
        .then(() => {
          this.close();
          this.$globalEvents.emit("show-notification", "Settings saved", "success");
        })
        .catch((error) => {
          console.error(error);
          this.$globalEvents.emit("show-notification", "Error saving settings", "error");
        });
    },
  },
};
</script>
<style src="../assets/css/settings-modal.css"></style>
