<template>
  <div class="settings-popup-modal" v-if="showModal">
    <div class="popup-modal modal-xl">
      <div class="popup-modal-header">
        <div class="title">Drone (#{{ drone.id }}) Control</div>
        <div class="close" @click="close">X</div>
      </div>
      <div class="popup-modal-body">
        <div class="row">
          <div class="col text-left">
            <button @click="enableManualControl" v-if="!drone.manualControlEnabled()" class="m-1 color-green">Enable Manual Control</button>
            <button @click="$droneSystem.shutdown(drone.id)" v-if="drone.isConnected()" class="m-1 color-red">Shutdown</button>
            <button @click="$droneControl.startRecording()" v-if="!drone.isRecording()" class="m-1 color-green">Record</button>
            <button @click="$droneControl.stopRecording()" v-if="drone.isRecording()" class="m-1 color-orange">Stop Recording</button>
          </div>
          <div class="col training-mode-container">
            <!-- Training mode Select -->
            <label for="trainingMode" class="training-mode-label">
              <info-message message="Select the command you want to train" />
              Training Mode
            </label>
            <select id="trainingMode" class="m-1" @change="updateTargets" :disabled="!drone.manualControlEnabled()" v-model="trainingMode">
              <option :value="null">None</option>
              <option v-for="model in $trainingSystem.models" v-bind:key="model.key" :value="model.key">{{ model.label }}</option>
            </select>
          </div>
          <div class="col text-right">
            <button @click="$droneControl.launch" :disabled="!drone.manualControlEnabled() || drone.isFlying()" class="m-1 color-green">Launch</button>
            <button @click="$droneControl.land" :disabled="!drone.manualControlEnabled() || !drone.isFlying()" class="m-1 color-red">Land</button>
            <button @click="$droneControl.returnToBase" :disabled="!drone.manualControlEnabled() || !drone.isFlying()" class="m-1 color-orange">Return</button>
          </div>
        </div>
        <div :class="'row' + calculateClass()" v-if="trainingMode">
          <div class="col">
            <div class="training-panel mb-2">
              <intelligent-navigation-fields v-if="trainingMode === 'IntelligentNavigation'" :drone="drone" />
              <altitude-estimation-fields v-if="trainingMode === 'AltitudeEstimation'" :drone="drone" />
            </div>
          </div>
        </div>

        <flight-dashboard :drone="drone" />

        <!-- Camera view -->
        <camera-view :cameraViewSrc="cameraViewSrc" :drone="drone" />

        <!-- Control inputs -->
        <control-inputs :drone="drone" @update-targets="updateTargets" :rotation="rotation" :altitude="altitude" :forwardSpeed="forwardSpeed" />

        <!-- Legend -->
        <hotkey-legend :drone-control="$droneControl" :drone="drone" @update-targets="updateTargets" />

        <!-- Camera controls -->
        <drone-feature-camera-controls v-if="$droneControl.isDesktop() && (drone.camera.canMoveX || drone.camera.canMoveY || drone.camera.canZoom)" :drone="drone" />
      </div>
    </div>
  </div>
</template>

<script>
import DroneFeatureCameraControls from './drone/DroneFeatureCameraControls.vue'
import InfoMessage from "@/components/Notification/InfoMessage.vue";
// Load droneControl
import { droneControl } from '@/features/droneControl.js'
import IntelligentNavigationFields from "@/modals/drone/TrainingMode/IntelligentNavigationFields.vue";
import AltitudeEstimationFields from "@/modals/drone/TrainingMode/AltitudeEstimationFields.vue";
import HotkeyLegend from "@/modals/ManualControl/HotkeyLegend.vue";
import CameraView from "@/modals/ManualControl/CameraView.vue";
import ControlInputs from "@/modals/ManualControl/ControlInputs.vue";
import FlightDashboard from "@/modals/ManualControl/FlightDashboard.vue";

export default {
  components: {
    FlightDashboard,
    CameraView,
    HotkeyLegend,
    IntelligentNavigationFields,
    AltitudeEstimationFields,
    InfoMessage,
    ControlInputs,
    DroneFeatureCameraControls
  },
  data() {
    return {
      rotation: 0,
      altitude: 0,
      forwardSpeed: 0,
      vtol: 0,
      cameraViewSrc: '',
      trainingMode: '',
      drone: null
    };
  },
  created() {
    // Load drone
    this.drone = this.$droneSystem.getDroneById(this.droneId)

    // Load droneControl
    this.$droneControl = new droneControl(this)

    this.reloadData()
    this.$globalEvents.on('drones-updated', () => {
      this.reloadData()
    })
  },
  props: {
    showModal: Boolean,
    droneId: Number
  },
  methods: {
    reloadData() {
      this.$droneSystem.fetchDroneImage(this.drone.id)
      const freshDrone = this.$droneSystem.getDroneById(this.drone.id)
      this.drone.image = freshDrone.getImage()
      this.drone.configuration = freshDrone.configuration

      this.cameraViewSrc = freshDrone.getImage()
      this.rotation = this.drone.getTargetRotation()
      this.altitude = this.drone.getTargetAltitude()
      this.vtol = this.drone.getTargetVtolPosition()
      this.forwardSpeed = this.drone.getTargetSpeed()
      this.trainingMode = this.drone.getTrainingMode()
    },
    close() {
      this.$emit("close-modal");
    },
    calculateClass() {
      if (this.trainingMode) {
        return ' slide-in'
      } else {
        return ' slide-out'
      }
    },
    updateTargets(inputData = null) {
      if (!this.$globalEvents.isComponentDisplayed(this) || !this.drone.manualControlEnabled()) return;
      const drone = this.drone
      const newState = {
        payload: {}
      }
      if (inputData) {
        this.rotation = inputData.rotation
        this.altitude = inputData.altitude
        this.forwardSpeed = inputData.forwardSpeed
      }
      newState.payload['targetRotation'] = this.rotation
      newState.payload['targetAltitude'] = this.altitude
      newState.payload['targetSpeed'] = this.forwardSpeed
      newState.payload['trainingMode'] = this.trainingMode

      // Add motor values
      const droneMotors = this.drone.motors
      newState.motors = newState.motors || {}
      for (let i = 0; i < droneMotors.length; i++) {
        newState.motors[i] = droneMotors[i].toJSON()
      }

      this.$droneSystem.manualControl(drone, newState)
    }
  },
};
</script>

<style src="../assets/css/settings-modal.css"></style>
<style>
.training-panel[style*="block"] {
  max-height: 100px; /* Or whatever max height you want */
}
.training-mode-label {
  text-align: left;
  width: 50%;
}
.training-mode-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.slide-in {
  transition: max-height 0.2s ease-out;
  max-height: 100px;
  overflow: hidden;
}
.slide-out {
  transition: max-height 0.2s ease-out;
  max-height: 0;
  overflow: hidden;
}

</style>