<template>
  <div v-if="marker.MY_CLASS === 'Drone'" class="drone-marker-data-container">
    <table>
      <tbody>
      <tr>
        <td>Drone ID</td>
        <td class="badge bg-primary m-1">{{ marker.id }}</td>
        <td>Drone battery</td>
        <td class="badge bg-primary m-1">{{ marker.getBatteryLevel() }}%</td>
      </tr>
      <tr>
        <td>Drone speed</td>
        <td class="badge bg-primary m-1">{{ marker.getSpeed() }} km/h</td>
        <td>Drone altitude</td>
        <td class="badge bg-primary m-1">{{ marker.getAltitude() }} m</td>
      </tr>
      <tr>
        <td>Current task</td>
        <td class="badge bg-primary m-1">{{ marker.getCurrentTask() }}</td>
        <td>Coordinates (Lat, Lng)</td>
        <td class="badge bg-primary m-1">{{ marker.lat }}<br><br>{{ marker.lng }}</td>
      </tr>
      <!-- Last seen -->
       <tr>
        <td>Last seen</td>
        <td class="badge bg-primary m-1">{{ marker.lastSeen() }} Seconds ago</td>
        <td></td>
        <td></td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'DroneMarkerData',
  props: {
    marker: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped>
.drone-marker-data-container .table {
  font-size: 0.8em;
}
.drone-marker-data-container .badge {
  vertical-align: middle;
}
</style>