<template>
  <!-- Modal that is shown when hovering over a marker -->
  <div v-if="show" class="popup-text" :style="coordinateStyle">
    <div class="popup-text-content">
      <div class="popup-text-body">
        <p>{{ message }}</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MarkerHoverModal',
  data() {
    return {
      message: null,
      show: false,
      coordinates: {
        x: 0,
        y: 0
      }
    }
  },
  created() {
    // Event for globalEvents
    this.$globalEvents.on('show-popup', this.showPopup);

    // Event for globalEvents
    this.$globalEvents.on('hide-popup', this.hidePopup);
  },
  computed: {
    coordinateStyle() {
      return {
        position: 'absolute',
        top: `${this.coordinates.y - 60}px`,
        left: `${this.coordinates.x + 20}px`
      }
    }
  },
  methods: {
    showPopup(event, message) {
      this.message = message;
      this.coordinates.x = event.clientX;
      this.coordinates.y = event.clientY;
      this.show = true;
    },
    hidePopup() {
      this.show = false;
    }
  }
}
</script>

<style>
.popup-text {
  position: absolute;
  z-index: 10001;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  text-align: left;
}
.popup-text-content {
  display: flex;
  flex-direction: column;
}
.popup-text-body {
  display: flex;
  font-size: 16px;
  flex-direction: column;
  padding: 10px;
}
.popup-text-body p {
  margin: 0;
}
</style>
