<template>
  <div class="header">
    <div class="title-area">
      <img style="width: 256px; height: 48px; float:left;" alt="Header logo" src="../assets/logo.png">
    </div>
    <div class="menu">
      <ul>
        <li @click="$emit('changeView', 'map')">Map</li>
        <li @click="$emit('changeView', 'trainingtool')">Training tool</li>
        <li @click="$emit('changeView', 'help')">Help</li>
        <li @click="showModal = true">Settings</li>
        <li @click="logout()">Logout</li>
      </ul>
    </div>
  </div>

  <!-- Modals -->
  <SettingsModal :showModal="showModal" @close-settings-modal="showModal = false" @save-settings="saveSettings" />
</template>
<script>
import SettingsModal from "../modals/SettingsModal.vue";
export default {
  data() {
    return {
      showModal: false,
    };
  },
  components: {
    SettingsModal,
  },
  methods: {
    logout() {
      this.$userSystem.logout()
          .then(() => {
            this.$userSystem.setToken(null);
            // Refresh
            window.location.reload()
          })
          .catch(response => {
            console.error(response)
          });
    },
    saveSettings(settings) {
      this.showModal = false;
      console.log(settings);
    }
  }
}
</script>

<!-- Load header.css -->
<style src="../assets/css/header.css"></style>
