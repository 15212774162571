<template>
  <div class="container">
    <div class="card">
      <div class="card-body">
        <!-- Training tool description -->
        <div class="row mb-5">
          <div class="col-12">
            <h3>Training Tool</h3>
            <p>Use this tool to train your models and classify images.</p>
            <p>Set data status to completed to train the model.</p>
            <p>The completed data will be trained on the next training batch.</p>
          </div>
        </div>
        <!-- New data entry -->
        <div class="row mb-5 float-right">
          <div class="col-6">
            <div>
              <button class="btn btn-primary float-right" @click="showDataEntryModal = true">New Data</button>
            </div>
          </div>
          <div class="col-6">
            <div class="refresh-button-container">
              <!-- Refresh Button -->
              <refresh-button @refresh-data="fetchData" />
            </div>
          </div>
        </div>
        <training-filters @update-filters="updateFilters" />
        <table class="table">
          <thead>
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Model</th>
            <th scope="col">Status</th>
            <th scope="col">Date created</th>
            <th scope="col">Actions</th>

          </tr>
          </thead>
          <tbody v-if="trainingData">
            <tr v-for="(data, index) in trainingData" :key="index">
              <td>{{ data.id }}</td>
              <td>
                <span class="badge bg-primary">{{ data.model }}</span>
              </td>
              <td>
                <span v-if="data.status == 'completed'" class="badge bg-success">Completed</span>
                <span v-else-if="data.status == 'pending'" class="badge bg-warning">Pending</span>
                <span v-else-if="data.status == 'trained'" class="badge bg-success">Trained</span>
                <span v-else-if="data.status == 'training'" class="badge bg-warning">Training</span>
              </td>
              <td>{{ formatDate(data.created_at) }}</td>
              <td>
                <!-- Edit button -->
                <button class="btn btn-primary" @click="openEditModal(data)">Classify</button>
                <!-- Delete button -->
                <button class="btn btn-danger" @click="confirmDelete(data.id)">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Edit Modal -->
    <TrainingToolEditModal :collected-data="editData" :show-modal="showEditModal" @close-modal="closeEditModal" />

    <!-- Image Modal -->
    <view-image-modal :selected-image="selectedImage" :show-modal="showImageModal" @close-modal="closeImageModal" />

    <!-- Data Entry Modal -->
    <TrainingDataEntryModal :show-modal="showDataEntryModal" @close-modal="closeDataEntryModal" />
  </div>
</template>

<script>
import TrainingToolEditModal from "@/modals/TrainingToolEditModal.vue";
import ViewImageModal from "@/modals/ViewImageModal.vue";
import TrainingFilters from "@/components/TrainingTool/TrainingFilters.vue";
import TrainingDataEntryModal from "@/modals/TrainingDataEntryModal.vue";
import RefreshButton from "@/components/Buttons/RefreshButton.vue";

export default {
  components: {RefreshButton, TrainingDataEntryModal, TrainingFilters, TrainingToolEditModal, ViewImageModal},
  created() {
    this.$globalEvents.on("training-data-updated", () => {
      this.$trainingSystem.fetchDirectly(this.filters).then((response) => {
        this.trainingData = response.data.data;
      });
    });
    this.trainingData = this.$trainingSystem.getTrainingData();
  },
  data() {
    return {
      trainingData: [],
      filters: {},
      showEditModal: false,
      editData: null,
      showImageModal: false,
      selectedImage: null,
      showDataEntryModal: false
    }
  },
  methods: {
    closeDataEntryModal() {
      this.showDataEntryModal = false;
    },
    closeEditModal() {
      this.showEditModal = false;
      this.editData = null;
    },
    closeImageModal() {
      this.showImageModal = false;
      this.selectedImage = null;
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const formattedDate = date.toLocaleDateString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' });
      const formattedTime = date.toLocaleTimeString('en-CA', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
      return `${formattedDate} ${formattedTime}`;
    },
    confirmDelete(id) {
      if (confirm("Are you sure you want to delete this row?")) {
        this.deleteRow(id);
      }
    },
    deleteRow(id) {
      this.$http.delete(`/api/training-data/${id}`)
          .then(() => {
            this.$globalEvents.emit("show-notification", "Training data deleted", "info")
            this.fetchData();
          })
          .catch(error => {
            console.error(error);
            this.$globalEvents.emit("show-notification", "Error fetching training data", "error")
          });
    },
    fetchData() {
      this.$globalEvents.emit("loading-screen:show");
      this.$trainingSystem.fetchDirectly(this.filters)
          .then((response) => {
            this.trainingData = response.data.data;
            this.$globalEvents.emit("loading-screen:hide");
          })
          .catch(error => {
            console.error(error);
            this.$globalEvents.emit("loading-screen:hide");
            this.$globalEvents.emit("show-notification", "Error fetching training data", "error")
          });
    },
    updateFilters(filters) {
      this.filters = filters
      this.$globalEvents.emit("loading-screen:show");
      this.$trainingSystem.fetchDirectly(this.filters)
        .then((response) => {
          this.trainingData = response.data.data
          this.$globalEvents.emit("loading-screen:hide");
        })
        .catch(error => {
          console.log(error)
          this.$globalEvents.emit("show-notification", "Error fetching training data", "error")
          this.$globalEvents.emit("loading-screen:hide");
        })
    },
    openEditModal(data) {
      this.editData = data;
      this.showEditModal = true;
    },
    openImageModal(image) {
      if (image === null) {
        return;
      }
      this.selectedImage = image;
      this.showImageModal = true;
    }
  }
}
</script>

