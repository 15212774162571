<template>
  <div class="settings-popup-modal" v-if="showModal">
    <div class="popup-modal modal-l">
      <div class="popup-modal-header">
        <div class="title">Connected Drone List</div>
        <div class="close" @click="close">X</div>
      </div>
      <div class="popup-modal-body">
        <div class="drone-list">
          <div class="row">
            <div class="col badge bg-primary m-1">ID</div>
            <div class="col badge bg-primary m-1">Class</div>
            <div class="col badge bg-primary m-1">Rank</div>
            <div class="col badge bg-primary m-1">Last seen</div>
            <div class="col badge bg-primary m-1">Actions</div>
          </div>
          <!-- Drone list -->
          <div
            class="row mb-2"
            v-for="(drone, idx) in $droneSystem.getActiveDrones()"
            v-bind:key="idx">
            <div class="col">{{ drone.id }}</div>
            <div class="col">{{ drone.class }}</div>
            <div class="col">
              <span v-if="drone.parent_id" class="badge bg-secondary">Slave</span>
              <span v-if="!drone.parent_id" class="badge bg-primary">Master</span>
            </div>
            <div class="col" v-if="drone.lastSeen() !== false">{{ drone.lastSeen() }}s</div>
            <div class="col" v-else>N/A</div>
            <div class="col"><button class="btn btn-primary" @click="selectDrone(drone)">Select</button></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      drones: [],
    };
  },
  props: {
    showModal: Boolean,
  },
  created() {
    this.updateData();
  },
  watch: {
    showModal: function (val) {
      if (val) {
        this.updateData();
      }
    },
  },
  methods: {
    selectDrone(drone) {
      this.$globalEvents.emit('objectSelectOverride', null)
      this.$globalEvents.emit('marker-clicked', drone)
    },
    updateData() {
      this.drones = this.$droneSystem.getDrones()
    },
    close() {
      this.$emit("close-modal");
    }
  },
};
</script>
<style src="../assets/css/settings-modal.css"></style>
