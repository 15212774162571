<template>
  <!-- Dashboard to view drone flight data -->
  <div class="row flight-dashboard" v-if="drone.isFlying()">
    <!-- Speed -->
    <div class="col-3">
      <label class="" for="speed">Speed ({{ parseInt(drone.getSpeed()) }} km/h)</label>
    </div>

    <!-- Altitude -->
    <div class="col-3">
      <label for="altitude">Altitude ({{ parseInt(drone.getAltitude()) }} m)</label>
    </div>

    <!-- Battery -->
    <div class="col-3">
      <label :class="calculateBatteryClass" for="battery">Battery ({{ parseInt(drone.getBatteryLevel()) }}%)</label>
    </div>

    <!-- Compass -->
    <div class="col-3">
      <label for="compass">Compass ({{ parseInt(drone.compass) }})</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FlightDashboard',
  props: {
    drone: Object,
  },
  computed: {
    calculateBatteryClass() {
      return {
        'text-danger': this.drone.battery < 20,
        'text-warning': this.drone.battery >= 20 && this.drone.battery < 50,
        'text-success': this.drone.battery >= 50,
      };
    },
  },
};
</script>

<style>
.flight-dashboard {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>