export class droneControl {

    constructor(ctx) {
        this.drone = ctx.drone;
        this.$droneSystem = ctx.$droneSystem
        this.$globalEvents = ctx.$globalEvents
    }

    isDesktop() {
        const keys = ["Win", "Mac", "Linux"];
        return keys.some(key => navigator.platform.includes(key));
    }

    enableManualControl() {
        this.$droneSystem.sendCommand(this.drone.id, "updateState", {
            payload: {
                currentScript: null
            }
        })

        this.$globalEvents.emit("show-notification", "Manual control enabled", "warning");
    }

    launch() {
        this.$droneSystem.sendCommand(this.drone.id, "updateState", {
            payload: {
                flightStatus: true,
                currentScript: null
            }
        })

        this.$globalEvents.emit("show-notification", "Drone launched", "info");
    }

    land() {
        this.$droneSystem.sendCommand(this.drone.id, "updateState", {
            payload: {
                flightStatus: false,
                currentScript: null
            }
        })

        this.$globalEvents.emit("show-notification", "Drone landed", "info");
    }

    returnToBase() {
        this.$droneSystem.sendCommand(this.drone.id, "updateState", {
            payload: {
                returnToBase: true,
                currentScript: null
            }
        })

        this.$globalEvents.emit("show-notification", "Drone returning to base", "info");
    }

    startRecording() {
        const drone = this.drone
        drone.camera.recording = true
        this.$droneSystem.sendCommand(this.drone.id, "record")

        this.$globalEvents.emit("show-notification", "Recording started", "info");
    }

    stopRecording() {
        const drone = this.drone
        drone.camera.recording = false
        this.$droneSystem.sendCommand(this.drone.id, "stopRecording")

        this.$globalEvents.emit("show-notification", "Recording stopped", "info");
    }

    resetMotors() {
        this.drone.motors.forEach(motor => {
            motor.fixedSpeed();
        });
    }

    droneControlAction(action) {
        switch (action) {
            case "moveForward":
                // Regulate motor speeds
                this.drone.motors.forEach(motor => {
                    // Only back motors should increase speed (motor.type string includes bottom)
                    if (motor.type.includes("back")) {
                        motor.fixedSpeed(25);
                    } else {
                        motor.fixedSpeed(-25);
                    }
                });
                break;
            case "moveBackward":
                // Regulate motor speeds
                this.drone.motors.forEach(motor => {
                    // Only front motors should increase speed (motor.type string includes top)
                    if (motor.type.includes("front")) {
                        motor.fixedSpeed(25);
                    } else {
                        motor.fixedSpeed(-25);
                    }
                });
                break;
            case "tiltLeft":
                // Regulate motor speeds
                this.drone.motors.forEach(motor => {
                    // Only right motors should increase speed (motor.type string includes right)
                    if (motor.type.includes("right")) {
                        motor.fixedSpeed(25)
                    } else {
                        motor.fixedSpeed(-25);
                    }
                });
                break;
            case "tiltRight":
                // Regulate motor speeds
                this.drone.motors.forEach(motor => {
                    // Only left motors should increase speed (motor.type string includes left)
                    if (motor.type.includes("left")) {
                        motor.fixedSpeed(25);
                    } else {
                        motor.fixedSpeed(-25);
                    }
                });
                break;
            case "rotateLeft":
                // Regulate motor speeds
                this.drone.motors.forEach(motor => {
                    if (motor.type == "left_front" || motor.type == "right_back") {
                        motor.fixedSpeed(10);
                    } else {
                        motor.fixedSpeed(-10);
                    }
                });
                break;
            case "rotateRight":
                // Regulate motor speeds
                this.drone.motors.forEach(motor => {
                    if (motor.type == "right_front" || motor.type == "left_back") {
                        motor.fixedSpeed(10);
                    } else {
                        motor.fixedSpeed(-10);
                    }
                });
                break;
            case "increaseAltitude":
                // Regulate motor speeds
                this.drone.motors.forEach(motor => {
                    motor.changeBaseSpeed(1);
                    motor.fixedSpeed();
                });
                break;
            case "decreaseAltitude":
                // Regulate motor speeds
                this.drone.motors.forEach(motor => {
                    motor.changeBaseSpeed(-1);
                    motor.fixedSpeed();
                });
                break;
            default:
                break;
        }
    }
}