<template>
  <div
      v-if="dronesConnected > 0"
      @mouseover="$globalEvents.emit('show-popup', $event, 'Amount of drones connected to your network.')"
      @mouseout="$globalEvents.emit('hide-popup')"
      class="drones-connected-label active"
      @click="showModal = true"
  >
    <span class="drones-connected-label__text">Drones connected: {{ dronesConnected }}</span>
  </div>
  <div v-else class="drones-connected-label inactive">
    <span class="drones-connected-label__text">No drones connected</span>
  </div>
  <drone-list-modal :show-modal="showModal" @close-modal="showModal = false" />
</template>

<script>
import DroneListModal from "../../modals/DroneListModal.vue";

export default {
  name: 'DronesConnectedLabel',
  components: {
    DroneListModal,
  },
  data() {
    return {
      dronesConnected: 0,
      showModal: false
    }
  },
  created() {
    this.$globalEvents.on('drones-updated', () => {
      this.dronesConnected = this.$droneSystem.getActiveDrones().length;
    })
  }
}
</script>
<style src="../../assets/css/drones.css"></style>