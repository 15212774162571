export default class Socket {

    constructor(appHandle) {
        this.appHandle = appHandle;
        this.events = [];

        // Connect
        this.connect();
    }

    connect() {
        try {
            let method = 'ws://';
            if (process.env.VUE_APP_WS_URL.startsWith('https')) {
                method = 'wss://';
            }

            this.socket = new WebSocket(method + process.env.VUE_APP_WS_URL + '/app/' + process.env.VUE_APP_WS_KEY);
            this.socket.onopen = this.connected.bind(this);
            this.socket.onmessage = this.message.bind(this);
            this.socket.onclose = this.disconnected.bind(this);
            this.socket.onerror = this.error.bind(this);
        } catch (e) {
            console.log('Failed to connect to server');
            console.error(e);
        }

        // Notification event
        this.listen('show-notification', (data) => {
            this.appHandle.getGlobalEvents().emit('show-notification', data.params.message, data.params.type);
        });
    }

    connected() {
        console.log('Connected to server');
    }

    listen(event, callback) {
        this.events.push({event: event, callback: callback});
    }

    message(event) {
        // Json
        const data = JSON.parse(event.data);

        // Trigger event
        this.events.forEach(item => {
            if (item.event === data.event) {
                item.callback(data);
            }
        });
    }

    disconnected() {
        console.log('Disconnected from server');

        // Reconnect
        this.connect();
    }

    error() {
        // Emit notification
        this.appHandle.getGlobalEvents().emit('show-notification', 'Failed to connect to server.', 'error');
    }

    send(payload) {
        if (this.socket && this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(payload);
        }
    }

    sendEvent(event, data=null) {
        if (this.appHandle.getUserSystem().user === null) {
            return;
        }
        const payload = {
            event: event,
            userId: this.appHandle.getUserSystem().user.id
        };
        if (data !== null) {
            payload.params = data;
        }
        this.send(JSON.stringify(payload));
    }
}