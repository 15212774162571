<template>
  <div class="password-recovery-card" style="width: 100%; max-width: 600px;">
    <div class="card-header">
      <h3>Password Recovery</h3>
    </div>
    <div class="row">
      <img style="width: 256px; height: 48px; float:left; margin: 2px;" alt="Vue logo" src="../assets/logo.png">
    </div>
    <div class="card-body">
      <form @submit="onSubmit">
        <div class="form-group" id="email-group">
          <label for="email">Email</label>
          <input id="email" v-model="user.email" type="email" required placeholder="Enter email" class="form-control">
        </div>
      </form>
      <div v-if="errorMessage" class="alert alert-danger m-1">{{ errorMessage }}</div>
      <div v-if="successMessage" class="alert alert-success m-1">{{ successMessage }}</div>
    </div>
    <div class="card-footer ">
      <button type="submit" @click="onSubmit" class="btn btn-primary m-1">Submit</button>
      <button @click="close" class="btn btn-secondary m-1">Close</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {
        email: '',
      },
      errorMessage: '',
      successMessage: ''
    }
  },
  methods: {
    clearMessages() {
      this.errorMessage = '';
      this.successMessage = '';
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.clearMessages();
      // Use this.$userSystem to handle password recovery
      this.$userSystem.recoverPassword(this.user.email)
          .then(() => {
            this.successMessage = 'Password recovery email sent!';
          })
          .catch(() => {
            this.errorMessage = "Failed to send password recovery email, please check your email."
          });
      return false;
    },
    close() {
      this.$emit('close');
    },
  }
}
</script>

