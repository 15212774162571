<template>
  <div class="row m-1 d-flex justify-content-center align-items-center">
    <div class="card" style="width: 60%; max-width: 600px;">
      <div v-if="!showPasswordRecovery">
        <div class="card-header">
          <h3>Login</h3>
        </div>
        <div class="card-body">
          <form @submit="onSubmit">
            <div class="form-group" id="email-group">
              <label for="email">Email</label>
              <input id="email" v-model="user.email" type="email" required placeholder="Enter email" class="form-control">
            </div>

            <div class="form-group" id="password-group">
              <label for="password">Password</label>
              <input id="password" v-model="user.password" type="password" required placeholder="Enter password" class="form-control">
            </div>

            <!-- Remember Password Checkbox -->
            <div class="form-group form-check d-flex justify-content-start" style="margin-top: 25px;">
              <input type="checkbox" class="form-check-input" id="rememberPassword" v-model="user.remember" style="margin-right: 10px;">
              <label class="form-check-label" for="rememberPassword">Remember Password</label>
            </div>
          </form>
          <div v-if="errorMessage" class="alert alert-danger m-1">{{ errorMessage }}</div>
          <div v-if="successMessage" class="alert alert-success m-1">{{ successMessage }}</div>
        </div>
        <div class="card-footer d-flex justify-content-between">
          <div>
            <button type="submit" @click="onSubmit" class="btn btn-primary m-1">Login</button>
            <button @click="register" class="btn btn-secondary m-1">Register</button>
          </div>
          <button @click="openPasswordRecovery" class="btn btn-link m-1">Forgot Password</button>
        </div>
      </div>
      <PasswordRecoveryView v-else @close="closePasswordRecovery" />
    </div>
  </div>
</template>

<script>
import PasswordRecoveryView from '@/components/PasswordRecoveryView.vue';
import './assets/css/login.css'

export default {
  components: {
    PasswordRecoveryView,
  },
  data() {
    return {
      user: {
        email: '',
        password: '',
        remember: false
      },
      errorMessage: '',
      successMessage: '',
      showPasswordRecovery: false,
    }
  },
  methods: {
    clearMessages() {
      this.errorMessage = '';
      this.successMessage = '';
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.clearMessages();
      // Use this.$userSystem to handle login
      this.$userSystem.login(this.user.email, this.user.password, this.user.remember)
          .then(response => {
            // Save token
            this.$userSystem.setToken(response.data.meta.token)
            // Refresh
            window.location.reload()
          })
          .catch(() => {
            this.errorMessage = "Failed to login, please check login data."
          });
      return false;
    },
    register() {
      this.clearMessages();

      // @DISABLED
      this.errorMessage = "Registration is disabled."
      return false;
    },
    openPasswordRecovery() {
      this.showPasswordRecovery = true;
    },
    closePasswordRecovery() {
      this.showPasswordRecovery = false;

     /* // Use this.$userSystem to handle registration
      this.$userSystem.register(this.user.email, this.user.password)
          .then(response => {
            this.successMessage = 'Registration successful!';
            this.$userSystem.setToken(response.meta.token)
          })
          .catch(() => {
            this.errorMessage = "Failed to register, please check registration data."
          });
      return false;*/
    },
  }
}
</script>