
export default class Motor {
    speed = 0;
    direction = 0;
    type = "";
    baseLineSpeed = 0;

    constructor(payload) {
        if (payload) {
            this.speed = payload["speed"];
            if (payload["direction"]) {
                this.direction = payload["direction"];
            }
            this.type = payload["type"];
        }
    }
    getSpeed() {
        return this.speed;
    }

    reduceSpeed(amount) {
        this.speed -= amount;
        if (this.speed < 0) {
            this.speed = 0;
        }
    }

    changeBaseSpeed(amount) {
        this.baseLineSpeed += amount;
        if (this.baseLineSpeed < 0) this.baseLineSpeed = 0;
        if (this.baseLineSpeed > 100) this.baseLineSpeed = 100;
    }

    fixedSpeed(addSub = 0) {
        this.speed = this.baseLineSpeed + addSub;
    }

    increaseSpeed(amount) {
        this.speed += amount;
        if (this.speed > 100) {
            this.speed = 100;
        }
    }

    setSpeed(speed) {
        this.speed = speed;
    }

    toJSON() {
        return {
            speed: this.speed,
            direction: this.direction,
            type: this.type
        }
    }
}