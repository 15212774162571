<template>
  <div>
    <div
        v-for="(notification, index) in notifications"
        :key="index"
        :class="calculateClass(notification)"
        :style="'bottom: ' + calcPosition(notification.id) + 'px'"
    >
      <div>{{ notification.message }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotificationPopup',
  created() {
    // Event for globalEvents
    this.$globalEvents.on('show-notification', this.showPopup);
  },
  data() {
    return {
      notifications: [],
    };
  },
  methods: {
    calculateClass(notification) {
      const myClass = notification.type + ' notification-popup ';
      if (notification.show) {
        return myClass + 'show';
      }
      return myClass;
    },
    calcPosition(id) {
      let position = 20;
      for (let i = 0; i < this.notifications.length; i++) {
        if (this.notifications[i].id === id) {
          return position;
        }
        position += 80;
      }
      return position;
    },
    getNotificationById(id) {
      return this.notifications.find((notification) => notification.id === id);
    },
    showPopup(message, type = 'info') {
      const newPopup = {
        message,
        type,
        id: Math.random(),
        show: true
      };
      // Put in front of array.
      this.notifications.unshift(newPopup);
      setTimeout(() => {
        this.hidePopup(newPopup.id);
      }, 3000);
    },
    hidePopup(id) {
      const notification = this.getNotificationById(id);
      if (notification) {
        notification.show = false;
        setTimeout(() => {
          this.notifications.splice(this.notifications.indexOf(notification), 1);
        }, 500);
      }
    }
  }
}
</script>

<style>
.notification-popup {
  position: fixed;
  right: 20px;
  padding: 20px;
  color: white;
  border-radius: 5px;
  z-index: 20000;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.notification-popup.info {
  background-color: #2196F3;
}

.notification-popup.success {
  background-color: #4CAF50;
}

.notification-popup.error {
  background-color: #f44336;
}

.notification-popup.warning {
  background-color: #ff9800;
}

.notification-popup.show {
  opacity: 1;
}
</style>