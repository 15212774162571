
// Class that handles object markers
import DetectedObject from "@/models/object";

export default class ObjectSystem {
    // Objects is an array of Object objects

    constructor() {
        this.objects = [];
        this.selectedObject = null;
    }

    // Init object system
    init(appHandle) {
        console.log("ObjectSystem: Initialized");
        this.appHandle = appHandle;

        // Setup listeners
        this.appHandle.getSocket().listen('get-objects', (data) => {
            this.setObjects(data.data);
        });

        // Fetch objects from server interval
        this.fetchObjects();
        setInterval(() => {
            this.fetchObjects();
        }, 1000);
    }

    // Update object list
    fetchObjects() {
        // Fetch via socket
        try {
            this.appHandle.getSocket().sendEvent('get-objects')
        } catch (error) {
            console.error(error);
        }
    }

    setObjects(objects) {
        this.objects = [];
        objects.forEach(object => {
            this.objects.push(new DetectedObject(object));
        });
        // Trigger global event
        this.appHandle.getGlobalEvents().emit('objects-updated');
    }

    getObjectsByType(type) {
        const objectList = [];
        this.objects.forEach((obj) => {
            if(obj.type === type) {
                objectList.push(obj);
            }
        })
        return objectList;
    }

    // Add an object to the object system
    addObject(object) {
        this.objects.push(object);

        // Send object to server
        this.appHandle.getHttp().post('/api/detected-objects', object)
            .catch((error) => {
                console.error(error);
            })
    }

    // Use socket to update object state
    updateState(object) {
        // Update object in object system
        this.objects.forEach((item, index) => {
            if(item.id === object.id) {
                this.objects[index] = object;

                // Send object to server
                this.appHandle.getHttp().put('/api/detected-objects/' + object.id, object)
                    .catch((error) => {
                        console.error(error);
                    })
            }
        });
    }

    // Remove an object by id
    deleteObjectById(object) {
        // remove object from objects array
        this.objects.forEach((item, index) => {
            if(item.id === object.id) {
                this.objects.splice(index, 1);

                // Send delete request to server
                this.appHandle.getHttp().delete('/api/detected-objects/' + object.id)
                    .catch((error) => {
                        console.error(error);
                    })
            }
        });
    }

    // Get objects
    getObjects() {
        if (this.objects.length > 0) {
            return this.objects;
        }
        return [];
    }

    // Get object by id
    getObjectById(id) {
        return this.objects.find(o => o.id === id);
    }

}