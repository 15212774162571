<template>
  <div class="row">
    <div class="col-md-12">
      <span style="color: purple;">Click on map to select a point to circle around</span>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectedPoint: null
    }
  },
  created() {
    this.$globalEvents.on('mapClicked', this.clickMap)

    // Delete markers created by this script
    this.$globalEvents.on('closeScriptView', () => {
      this.$mapSystem.deleteMarkerByCreatedBy('circleScript')
      this.selectedPoint = null
      this.$globalEvents.emit('refreshMap')
    })

    // Update markers on event openScriptView
    this.$globalEvents.on('openScriptView', () => {
      this.openView()
    })
  },
  methods: {
    openView() {
      const selectedDrone = this.$droneSystem.selectedDrone
      if (selectedDrone && selectedDrone.payload['currentScript'] === 'circle') {
        if (selectedDrone.payload['circleMarker']) {
          this.selectedPoint = {
            lat: selectedDrone.payload['circleMarker'].lat,
            lng: selectedDrone.payload['circleMarker'].lng
          }
          this.updateMarker()
        }
      }
    },
    updateMarker() {
      const selectedDrone = this.$droneSystem.selectedDrone
      if (selectedDrone && selectedDrone.payload['currentScript'] === 'circle' && this.selectedPoint) {
        if (selectedDrone.payload['circleMarker']) {
          this.$mapSystem.deleteMarkerById(selectedDrone.payload['circleMarker'].id)
        }
        // Random id
        const generatedId = Math.random().toString(36).substring(7)
        // Set circleMarker details
        this.$droneSystem.selectedDrone.payload['circleMarker'] = {
          id: generatedId,
          lat: this.selectedPoint.lat,
          lng: this.selectedPoint.lng
        }
        this.$droneSystem.updateState(this.$droneSystem.selectedDrone)
        this.$mapSystem.addMarker({
          id: generatedId,
          createdBy: 'circleScript',
          position: {
            lat: this.selectedPoint.lat,
            lng: this.selectedPoint.lng
          },
          icon: {
            url: 'https://maps.google.com/mapfiles/ms/icons/yellow.png',
            scaledSize: {
              width: 24,
              height: 24
            }
          }
        })
        this.$globalEvents.emit('refreshMap')
      }
    },
    clickMap(event) {
      this.selectedPoint = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng()
      }
      this.updateMarker()
    }
  }
}
</script>