export default class Base {
    constructor(data) {
        this.id = data.id;
        this.lat = data.lat;
        this.lng = data.lng;
        this.type = data.type;
        this.payload = data.payload;
        this.keepalive_at = data.keepalive_at;
        this.image = null;
    }

    isSameObject(other) {
        if (!other) {
            return false
        }
        return this.id === other.id && this.MY_CLASS === other.MY_CLASS
    }

    getPosition() {
        return {
            lat: this.lat,
            lng: this.lng
        }
    }

    isTimeout() {
        if (this.keepalive_at) {
            // Convert keepalive_at from "YYYY-MM-DD H:i:s" to a Date object
            const keepaliveDate = new Date(this.keepalive_at); // Adjust for timezone if necessary
            // Convert the date to a timestamp in milliseconds
            const keepalive_at_ms = keepaliveDate.getTime();

            // Return true if more than 60 seconds have passed
            return (Date.now() - keepalive_at_ms) > 60000
        }
        return true;
    }

    getImage() {
        return this.image
    }

    isDrone() {
        return this.MY_CLASS === "Drone"
    }

    isObject() {
        return this.MY_CLASS === "DetectedObject"
    }

    lastSeen() {
        const keepalive_at = this.keepalive_at // Timestamp
        if (keepalive_at) {
            // Convert keepalive_at from "YYYY-MM-DD H:i:s" to a Date object
            const keepaliveDate = new Date(keepalive_at); // Adjust for timezone if necessary
            // Convert the date to a timestamp in milliseconds
            const keepalive_at_ms = keepaliveDate.getTime();
            // Return the difference in milliseconds
            return Math.round((Date.now() - keepalive_at_ms) / 1000)
        }
        return 0
    }

}