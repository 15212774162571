import Camera from "@/models/camera";
import Base from './base';
import Waterbomb from "@/models/payloads/waterbomb";
import PayloadBase from "@/models/payloads/payloadBase";
import Motor from "@/models/motor";

/**
 * Drone model
 */
export default class Drone extends Base {
    MY_CLASS = "Drone";
    constructor(data) {
        super(data);
        this.id = data.id;
        this.lat = data.lat;
        this.lng = data.lng;
        this.parent_id = data.parent_id;
        this.uuid = data.uuid
        this.type = "drone"
        this.class = "unknown"
        this.keepalive_at = data.keepalive_at
        this.image = data.image
        if (data.payload) {
            this.payload = data.payload
            if (data.payload.class) {
                this.class = data.payload.class
            }
        }
        if (data.configuration) {
            this.configuration = data.configuration
        }
        const payloads = [];
        if (data.payload.payloads && data.payload.payloads.length > 0) {
            // Payload class
            data.payload.payloads.forEach(payload => {
                if (payload.type == "waterbomb") {
                    payloads.push(new Waterbomb(payload))
                } else {
                    payloads.push(new PayloadBase(payload))
                }
            });
        }
        this.payloads = payloads
        this.camera = new Camera(data.camera)
        this.motors = []
        if (this.payload["motors"]) {
            this.payload["motors"].forEach(motor => {
                this.motors.push(new Motor(motor))
            })
        }
    }

    canVTOL() {
        if (this.configuration && this.configuration.vtolEnabled) {
            return this.configuration.vtolEnabled
        }
    }

    getVtolPosition() {
        if (this.payload["vtolPosition"]) {
            return this.payload["vtolPosition"]
        }
        return null
    }

    getTargetVtolPosition() {
        if (this.payload["targetVtolPosition"]) {
            return this.payload["targetVtolPosition"]
        }
        return null
    }

    getDroneState() {
        // Add camera to payload
        this.payload["camera"] = this.camera

        const motors = []
        this.motors.forEach(motor => {
            motors.push(motor.toJSON())
        })

        return {
            id: this.id,
            lat: this.lat,
            lng: this.lng,
            parent_id: this.parent_id,
            keepalive_at: this.keepalive_at,
            payload: this.payload,
            camera: this.camera,
            motors: motors
        }
    }

    isRecording() {
        return this.camera.isRecording()
    }

    getImage() {
        if (this.image) {
            return this.image
        }
        return null
    }

    getPayloads() {
        return this.payloads
    }

    getTrainingMode() {
        if (this.payload["trainingMode"]) {
            return this.payload["trainingMode"]
        }
        return false
    }

    getCurrentTask() {
        if (this.payload["currentTask"]) {
            return this.payload["currentTask"]
        }
        return "N/A"
    }

    getEstimatedRange() {
        if (this.payload["range"]) {
            return this.payload["range"]
        }
        return 0
    }

    isConnected() {
        // If seen in the last 60 seconds, it is connected
        return !this.isTimeout()
    }

    getSpeed() {
        if (this.payload["speed"]) {
            return this.payload["speed"]
        }
        return 0
    }

    getTargetSpeed() {
        if (this.payload["targetSpeed"]) {
            return this.payload["targetSpeed"]
        }
        return 0
    }

    getMaxSpeed() {
        if (this.payload["maxSpeed"]) {
            return this.payload["maxSpeed"]
        }
        return 100
    }

    getVisionRange() {
        if (this.payload["visionRange"]) {
            return this.payload["visionRange"]
        }
        return 50
    }

    getAltitude() {
        if (this.payload["altitude"]) {
            return this.payload["altitude"]
        }
        return 0
    }

    getTargetAltitude() {
        if (this.payload["targetAltitude"]) {
            return this.payload["targetAltitude"]
        }
        return 0
    }

    getTrackedObject() {
        if (this.payload["trackObject"]) {
            return this.payload["trackObject"]
        }
        return null
    }

    getRotation() {
        if (this.payload["rotation"]) {
            return this.payload["rotation"]
        }
        return 0
    }

    getTargetRotation() {
        if (this.payload["targetRotation"]) {
            return this.payload["targetRotation"]
        }
        return 0
    }

    getMaxAltitude() {
        if (this.payload["maxAltitude"]) {
            return this.payload["maxAltitude"]
        }
        return 100
    }

    getBatteryLevel() {
        if (this.payload["battery"]) {
            return this.payload["battery"]
        }
        return 0
    }

    isMaster() {
        return !this.parent_id
    }

    getSlaves() {
        if (this.payload["slaves"]) {
            return this.payload["slaves"]
        }
        return []
    }

    getMasterId() {
        return this.parent_id
    }

    getCurrentScript() {
        if (this.payload["currentScript"]) {
            return this.payload["currentScript"]
        }
        return null
    }

    manualControlEnabled() {
        return !this.payload["currentScript"]
    }

    isFlying() {
        return this.payload["flightStatus"] === true
    }

    getLabel() {
        // This adds a label to the gmaps marker. It will return the label color and text
        // Label consists of noteworthy data, like low battery or timeout
        let label = ""
        let color = "black"
        if (this.isTimeout()) {
            label = "Connection lost"
            color = "white"
        }
        if (this.getBatteryLevel() < 20) {
            label += "\nLow Battery"
            color = "red"
        }
        // Position text above marker
        label += "\n \n \n \n"
        return {text: label, color: color, fontSize: "12px", className: "marker-drone-label"}
    }

    getIcon() {
        // Type assault
        if (this.class === "assault") {
            return require("../assets/icons/drone/drone_payload.png");
        }
        return require("../assets/icons/drone/drone_scout.png")
        // Master / Slave @TODO: This should be a supplemental icon to the main icon.
        /*if (this.isMaster()) {
            return "https://maps.google.com/mapfiles/ms/icons/green.png"
        } else {
            return "https://maps.google.com/mapfiles/ms/icons/lightblue.png"
        }*/
    }
}