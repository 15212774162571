
// Class that handles global events
export default class Events {
    constructor(appHandle) {
        this.appHandle = appHandle;
        this.events = [];
        this.keyStates = {};
        this.keyCallbacks = [];
        this.keyReleaseCallbacks = {};

        // Add event listeners
        window.addEventListener('keydown', (event) => {
            this.onKeyboardDown(event);
        });

        window.addEventListener('keyup', (event) => {
            this.onKeyboardUp(event);
        });

        setInterval(() => {
            this.triggerCallbacks();
        }, 50);
    }

    triggerCallbacks() {
        for (const [key, value] of Object.entries(this.keyCallbacks)) {
            if (this.keyStates[key]) {
                value.forEach(callback => {
                    callback(key);
                });
            }
        }
    }

    onKeyPress(key, callback) {
        if (Array.isArray(key)) {
            key.forEach(key => {
                // Check if array exists
                if (!this.keyCallbacks[key]) {
                    this.keyCallbacks[key] = [];
                }
                this.keyCallbacks[key].push(callback);
            });
        } else {
            // Check if array exists
            if (!this.keyCallbacks[key]) {
                this.keyCallbacks[key] = [];
            }
            this.keyCallbacks[key].push(callback);
        }
    }

    onKeyRelease(key, callback) {
        // Check if key is an array or string
        if (Array.isArray(key)) {
            key.forEach(key => {
                // Check if array exists
                if (!this.keyReleaseCallbacks[key]) {
                    this.keyReleaseCallbacks[key] = [];
                }
                this.keyReleaseCallbacks[key].push(callback);
            });
        } else {
            // Check if array exists
            if (!this.keyReleaseCallbacks[key]) {
                this.keyReleaseCallbacks[key] = [];
            }
            this.keyReleaseCallbacks[key].push(callback);
        }
    }

    removeKeyEvents(key) {
        // If array
        if (Array.isArray(key)) {
            key.forEach(key => {
                this.keyCallbacks[key] = [];
                this.keyReleaseCallbacks[key] = [];
            });
        } else {
            this.keyCallbacks[key] = [];
            this.keyReleaseCallbacks[key] = [];
        }
    }

    getPressedKeys() {
        const keys = [];
        for (const [key, value] of Object.entries(this.keyStates)) {
            if (value) {
                keys.push(key);
            }
        }
        return keys;
    }

    onKeyboardDown(event) {
        this.keyStates[event.key] = true;
    }

    onKeyboardUp(event) {
        this.keyStates[event.key] = false;
        if (this.keyReleaseCallbacks[event.key]) {
            this.keyReleaseCallbacks[event.key].forEach(callback => {
                callback();
            });
        }
    }

    on(eventName, callback) {
        this.events.push({"eventName": eventName, "callback": callback});
    }

    isComponentDisplayed(component) {
        // Check if component is displayed component.$el
        if (!component.$el) {
            return false;
        }
        // Check if component is displayed
        if (component.$el.style && component.$el.style.display === 'none') {
            return false;
        }
        return true;
    }

    emit(eventName, ...args) {
        this.events.forEach(event => {
            if (event.eventName === eventName) {
                event.callback(...args);
            }
        });
    }
}