export default class TrainingSystem {

    constructor() {
        this.trainingData = []
        this.models = [
            {"key": "IntelligentNavigation", "label": "Intelligent Navigation"},
            {"key": "AltitudeEstimation", label: "Altitude Estimation"},
        ]
    }

    init(appHandle) {
        console.log("DroneSystem: Initialized");
        this.appHandle = appHandle;

        this.fetchTrainingData();
    }

    // Fetch data by id
    getTrainingDataById(id) {
        return this.appHandle.getHttp().get('/api/training-data/' + id)
    }

    // Update drone list
    fetchTrainingData() {
        const ctx = this;
        this.appHandle.getHttp().get('/api/training-data')
            .then((response) => {
                ctx.trainingData = response.data.data;

                // Trigger global event
                ctx.appHandle.getGlobalEvents().emit('training-data-updated');
            })
            .catch((error) => {
                ctx.appHandle.getGlobalEvents().emit('show-notification', 'Failed to fetch training data.', 'error');
                console.error(error);
            })
    }

    addTrainingData(model, input, output, drone_id=0, status='pending') {
        return this.appHandle.getHttp().post('/api/training-data', {
            model: model,
            input_data: input,
            output_data: output,
            drone_id: drone_id,
            status: status
        })
    }

    fetchDirectly(filters) {
        return this.appHandle.getHttp().get('/api/training-data', {
            params: filters
        })
    }

    fetchModels() {
        return this.appHandle.getHttp().get('/api/neural-networks')
    }

    fetchStructure(model) {
        return this.appHandle.getHttp().get('/api/neural-networks/' + model + '/structure')
    }

    getTrainingData() {
        return this.trainingData;
    }
}