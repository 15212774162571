<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <span style="color: purple;">Click on object to track</span>
      </div>
    </div>
    <div class="row" v-if="selectedObject">
      <div class="col-md-6">
        <info-message message="The selected drone will track this objects movements." />
        <span style="color: purple;">Tracking object</span>
      </div>
      <div class="col-md-6"><span style="color: purple;">#{{ selectedObject.id }} ({{ selectedObject.type }})</span></div>
    </div>
    <!-- Distance from tracked object -->
    <div class="row" v-if="selectedObject">
      <div class="col-md-6">
        <info-message message="The distance from the selected drone to the tracked object." />
        <span style="color: purple;">Distance to target</span>
      </div>
      <div class="col-md-6"><span style="color: purple;">{{ $droneSystem.getDistance($droneSystem.selectedDrone, selectedObject.lat, selectedObject.lng) }}m</span></div>
    </div>
  </div>
</template>
<script>
import InfoMessage from "@/components/Notification/InfoMessage.vue";

export default {
  components: {InfoMessage},
  data() {
    return {
      selectedObject: null
    }
  },
  created() {
    this.$globalEvents.on('openScriptView', this.openView)
    this.$globalEvents.on('closeScriptView', this.closeView)
    this.openView()
  },
  methods: {
    openView() {
      const selectedDrone = this.$droneSystem.selectedDrone
      if (selectedDrone && selectedDrone.payload['currentScript'] === 'track') {
        if (selectedDrone.payload['trackObject']) {
          this.selectedObject = this.$objectSystem.getObjectById(selectedDrone.payload['trackObject'])
          if (this.selectedObject) {
            this.selectObject(this.selectedObject)
          }
        }
        this.$globalEvents.emit('objectSelectOverride', this.selectObject)
      }
    },
    selectObject(object) {
      const selectedDrone = this.$droneSystem.selectedDrone
      if (selectedDrone && selectedDrone.payload['currentScript'] === 'track') {
        if (object.isDrone()) {
          this.$globalEvents.emit('show-notification', 'Cannot track drone', 'error')
          return
        }
        // Remove if already tracking
        if (this.selectedObject && this.selectedObject.id === object.id) {
          this.selectedObject = null
          this.$droneSystem.selectedDrone.payload['trackObject'] = null
          this.$droneSystem.updateState(this.$droneSystem.selectedDrone)
          return
        }
        this.selectedObject = object
        this.$droneSystem.selectedDrone.payload['trackObject'] = object.id
        this.$droneSystem.updateState(this.$droneSystem.selectedDrone)
      }
    },
    closeView() {
      this.selectedObject = null
      this.$globalEvents.emit('objectSelectOverride', null)
    }
  }
}
</script>