<template>
  <!-- Modal that is shown when hovering over a marker -->
  <div v-if="marker" class="popup" :style="coordinateStyle">
    <div class="popup-content">
      <div class="popup-header color-primary">
        <h3 v-if="marker.isDrone()">Drone #{{ marker.id }} ({{ marker.class }})</h3>
        <h3 v-if="!marker.isDrone()">{{ marker.MY_CLASS }} #{{ marker.id }}</h3>
      </div>
      <div class="popup-body">
        <drone-marker-data v-if="marker.MY_CLASS === 'Drone'" :marker="marker" />
        <detected-object-marker-data v-if="marker.MY_CLASS === 'DetectedObject'" :marker="marker" />
      </div>
    </div>
  </div>
</template>

<script>
import DroneMarkerData from "@/modals/markers/DroneMarkerData.vue";
import DetectedObjectMarkerData from "@/modals/markers/DetectedObjectMarkerData.vue";

export default {
  name: 'MarkerHoverModal',
  components: {DetectedObjectMarkerData, DroneMarkerData},
  props: {
    marker: {
      type: Object,
      required: false
    },
    coordinates: {
      type: Object,
      required: false
    }
  },
  computed: {
    coordinateStyle() {
      return {
        position: 'absolute',
        top: `${this.coordinates.y - 60}px`,
        left: `${this.coordinates.x + 20}px`
      }
    }
  }
}
</script>

<style>
.popup {
  position: absolute;
  z-index: 10000;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  text-align: left;
}
.popup-content {
  display: flex;
  flex-direction: column;
}
.popup-header {
  text-align: center;
  padding: 5px;
  padding-bottom: 10px;
}
.popup-header h3 {
  margin: 0;
  float: left;
  text-align:left;
  width: 100%;
  height: 20px;
  font-size: 20px;
}
.popup-body {
  display: flex;
  font-size: 16px;
  flex-direction: column;
  padding: 10px;
}
.popup-body p {
  margin: 0;
}
</style>
