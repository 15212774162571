<template>
  <div class="loading-screen" v-if="show">
    <div class="loading-screen__content">
      <div class="loading-screen__spinner">
        <img src="../../assets/loader_with_text.gif" alt="Loading" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingScreen',
  data() {
    return {
      show: false,
    };
  },
  created() {
    this.$globalEvents.on('loading-screen:show', () => {
      this.show = true;
    });

    this.$globalEvents.on('loading-screen:hide', () => {
      this.show = false;
    });
  }
}
</script>

<style>
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 20000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-screen__content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loading-screen__spinner {
  width: 100px;
  height: 100px;
}

.loading-screen__spinner img {
  width: 100%;
  height: 100%;
}

</style>