<template>
  <div class="settings-popup-modal" v-if="showModal">
    <div class="popup-modal modal-lg">
      <div class="popup-modal-header">
        <div class="title">Drone Info</div>
        <div class="close" @click="close">X</div>
      </div>
      <div class="popup-modal-body">
        <!-- Bootstrap tabs -->
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="drone-info-tab" data-bs-toggle="tab" data-bs-target="#droneInfo" type="button" role="tab" aria-controls="droneInfo" aria-selected="true">Drone Info</button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="drone-data-tab" data-bs-toggle="tab" data-bs-target="#droneData" type="button" role="tab" aria-controls="droneData" aria-selected="false">Drone Data</button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="droneInfo" role="tabpanel" aria-labelledby="drone-info-tab">
            <drone-info-details v-if="drone" :drone="drone" />
          </div>
          <div class="tab-pane fade" id="droneData" role="tabpanel" aria-labelledby="drone-data-tab">
            <drone-data-view v-if="drone" :drone="drone" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import DroneDataView from "@/modals/drone/DroneDataView.vue";
import DroneInfoDetails from "@/modals/drone/DroneInfoDetails.vue";

export default {
  components: {DroneInfoDetails, DroneDataView},
  props: {
    showModal: Boolean,
    drone: Object,
  },
  methods: {
    close() {
      this.$emit("close-modal");
    }
  },
};
</script>
<style src="../assets/css/settings-modal.css"></style>
