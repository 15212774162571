<template>
  <div class="row control-inputs" v-if="drone">
    <div class="col-3">
      <label for="rotation">
        <info-message message="Modify the rotation of the drone, hotkey: A / D" />
        Rotation ({{ rotation }})
      </label><br>
      <input id="rotation" type="range" min="0" max="360" class="m-1"  :disabled="!drone.isFlying() || !drone.manualControlEnabled()" @change="$emit('update-targets', this.$data)" v-model="rotation">
    </div>
    <div class="col-3">
      <label for="altitude">
        <info-message message="Modify the altitude of the drone, hotkey: Q / E" />
        Altitude ({{ altitude }} m)
      </label><br>
      <input id="altitude" type="range" min="0" :max="drone.getMaxAltitude()" class="m-1" :disabled="!drone.isFlying() || !drone.manualControlEnabled()" @change="$emit('update-targets', this.$data)" v-model="altitude">
    </div>
    <div class="col-3">
      <label for="forwardSpeed">
        <info-message message="Modify the forward speed of the drone, hotkey: W / S" />
        Forward Speed ({{ forwardSpeed }} km/h)
      </label><br>
      <input id="forwardSpeed" type="range" min="0" :max="drone.getMaxSpeed()" class="m-1" :disabled="!drone.isFlying() || !drone.manualControlEnabled()" @change="$emit('update-targets', this.$data)" v-model="forwardSpeed">
    </div>
    <!-- VTOL -->
    <div class="col-3" v-if="drone.canVTOL()">
      <label for="vtol">
        <info-message message="Modify the VTOL of the drone, hotkey: Z / X / C" />
        VTOL ({{ vtol }})
      </label><br>
      <input id="vtol" type="range" min="0" max="100" class="m-1" :disabled="!drone.isFlying() || !drone.manualControlEnabled()" @change="$emit('update-targets', this.$data)" v-model="vtol">
    </div>
  </div>
</template>
<script>
import InfoMessage from "@/components/Notification/InfoMessage.vue";

export default {
  name: 'ControlInputs',
  components: {
    InfoMessage
  },
  props: {
    drone: Object,
  },
  created() {
    // Listen for drone-updated event
    this.$globalEvents.on('drone-updated', (drone) => {
      this.rotation = drone.getTargetRotation()
      this.altitude = drone.getTargetAltitude()
      this.forwardSpeed = drone.getTargetSpeed()
      this.vtol = drone.getTargetVtolPosition()
    });
  },
  data() {
    return {
      rotation: 0,
      altitude: 0,
      forwardSpeed: 0,
      vtol: 0
    };
  }
}
</script>

<style>
.control-inputs label {
  font-size: 12px;
}
</style>